import * as React from "react";
import {
  List,
  Datagrid,
  Create,
  DateField,
  Show,
  ReferenceField,
  SimpleShowLayout,
  FunctionField,
  ArrayField,
  useTranslate,
  useRefresh,
  useNotify,
  BooleanField,
  TextField as ReactAdminTextField,
  ShowButton,
} from "react-admin";
import { ListActions } from "../templates/ListActions";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { Link, useNavigate, useParams } from "react-router-dom";
import Page from "../CustomPages/SellReceipt/Page";
import { HTTP } from "../../axios";
import { url, handleRequestResponse } from "../../request";
import CheckIcon from "@mui/icons-material/Check";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Swal from "sweetalert2";
import swal from "sweetalert";
import moment from "moment";
import { permissions } from "../../components/Permissions/AllPermission";
import { hasPermission } from "../../App";
import Autocomplete from "@mui/lab/Autocomplete";
import { Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";

const BulkActionButtons = (props) => {
  const refresh = useRefresh();
  const translate = useTranslate();

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.selectedIds.length === 1 && (
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              const { value: formValues } = Swal.fire({
                title: "Confirm the transfering",
                html: '<span className="d-flex justify-content-center align-items-center gap-5"> <label className="text-dark ">Note:</label> <input  id="swal-input1"  className="swal2-input"></span>',
                focusConfirm: false,
                confirmButtonColor: "#117baf",
                confirmButtonText: translate("resources.root.submit"),
                preConfirm: () => {
                  HTTP.put(
                    `${url}/StoreTransferInvoices/${
                      props.selectedIds[0]
                    }?note=${document.getElementById("swal-input1").value}`
                  )
                    .then(() => {
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        console.log(err);
                        Swal.fire({
                          title: "Error",
                          html: err.response.data.message,
                        });
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                },
              });
            }}
          >
            <CheckIcon /> &nbsp; {translate("resources.root.accept")}
          </Button>
        )}

        <BulkAction {...props} bulkDeleteButton={true} />
      </div>
    </React.Fragment>
  );
};

export const StoreTransferInvoicesList = (props) => {
  return (
    <List
      debounce={1500}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions hasCreate="storetransfer.create" />}
    >
      <Datagrid
        bulkActionButtons={
          hasPermission([permissions.storeTransferAccept]) && (
            <BulkActionButtons />
          )
        }
      >
        <ReactAdminTextField source="id" label="resources.root.id" />

        <FunctionField
          sortBy={"storeId"}
          label="resources.root.store"
          render={(record) => (
            <Link
              to={`/Stores/${record.store.id}`}
            >{`${record.store.title}`}</Link>
          )}
        />

        <FunctionField
          sortBy={"storeId"}
          label="resources.root.toStore"
          render={(record) => (
            <Link
              to={`/Stores/${record.toStore.id}`}
            >{`${record.toStore.title}`}</Link>
          )}
        />

        <BooleanField source="received" label="resources.root.received" />
        <ReactAdminTextField
          source="totalSentQte"
          label="resources.root.totalQty"
        />
        <ReactAdminTextField
          source="transferedBy"
          label="resources.root.transferredBy"
        />
        {localStorage.getItem("showNote") === "true" && (
          <ReactAdminTextField source="note" label="resources.root.note" />
        )}
        {localStorage.getItem("showDriver") === "true" && (
          <ReactAdminTextField source="driver" label="resources.root.driver" />
        )}
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
        {localStorage.getItem("showLastUser") === "true" && (
          <ReactAdminTextField
            source="lastUser"
            label="resources.root.lastUser"
          />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const StoreTransferInvoicesShow = (props) => {
  const translate = useTranslate();
  const { id } = useParams();
  const [orders, setOrders] = React.useState([]);
  const [invoice, setInvoice] = React.useState([]);
  const classes = useStyles();
  React.useEffect(() => {
    if (id !== 0) {
      HTTP.get(`${url}/StoreTransferInvoices/${id}`).then((res) => {
        setOrders(res.data.sellOrders);
        setInvoice(res.data);
      });
    }
  }, [id]);
  return (
    <div>
      <PrintProvider>
        <Print single={true} name="foo">
          <div className={classes.printWrapper}>
            <Page
              invoice={invoice}
              order={invoice.storeTransferOrders}
              title={translate("resources.root.transferInvoice")}
              human={invoice.customer}
              showPrice={false}
              showCustomer={false}
              isTransferInvoice={true}
            />
            {/*<NewInvoice*/}
            {/*  ref={ref}*/}
            {/*  orders={orders}*/}
            {/*  invoice={invoice}*/}
            {/*  title={translate('resources.root.sellInvoice')}*/}
            {/*  human={invoice.customer}*/}
            {/*/>*/}
          </div>
        </Print>
        <NoPrint force>
          <Show>
            <SimpleShowLayout>
              <DateField
                source="date"
                label="resources.root.date"
                locales={"en-GB"}
              />
              <ReactAdminTextField
                source="driver"
                label="resources.root.driver"
              />
              <ReactAdminTextField
                source="transferedBy"
                label="resources.root.transferredBy"
              />
              <ReactAdminTextField source="note" label="resources.root.note" />
              <ArrayField source="storeTransferOrders">
                <Datagrid>
                  <FunctionField
                    label="resources.root.id"
                    render={(record) => (
                      <Link
                        to={`/Products/${record.product.id}`}
                      >{`${record.product.id}`}</Link>
                    )}
                  />
                  <FunctionField
                    label="resources.root.product"
                    render={(record) => record.product.name}
                  />
                  <FunctionField
                    label="resources.root.barcode"
                    render={(record) => record.product?.barcode}
                  />
                  <ReactAdminTextField
                    source="qte"
                    label="resources.root.sentQty"
                  />
                  <ReactAdminTextField
                    source="smallMeasureQte"
                    label="resources.root.smallMeasureQty"
                  />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Show>
          <Button
            variant="contained"
            onClick={() => {
              // let sidebar = document.getElementsByClassName('MuiDrawer-root');
              // let appMenu = document.getElementsByClassName('MuiPaper-root');
              // let appBar = document.getElementsByClassName('theRoot');
              // sidebar[0].style.display = 'none';
              // appMenu[0].style.display = 'none';
              // appBar[0].style.marginTop = '-100px';
              // window.print();
              // sidebar[0].style.display = 'block';
              // appMenu[0].style.display = 'block';
              // appBar[0].style.marginTop = '40px';
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              sidebar[0].style.display = "none";
              window.print();
              sidebar[0].style.display = "block";
            }}
          >
            {translate("resources.root.print")}
          </Button>
        </NoPrint>
      </PrintProvider>
    </div>
  );
};

export const StoreTransferInvoicesCreate = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const navigate = useNavigate();
  const [stores, setStores] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [generalData, setGeneralData] = React.useState({
    storeId: 0,
    toStoreId: 0,
    driver: null,
    transferdBy: null,
    note: null,
    date: moment(new Date()).format("YYYY-MM-DD"),
  });

  const [storeTransferOrders, setStoreTransferOrders] = React.useState(
    JSON.parse(localStorage.getItem("storeTransferOrders")) || []
  );
  localStorage.setItem(
    "storeTransferOrders",
    JSON.stringify(storeTransferOrders)
  );
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [qty, setQty] = React.useState(0);
  const [smallMeasureQty, setsmallMeasureQty] = React.useState(0);

  const handlePushValue = (newObject) => {
    console.log(newObject);
    if (newObject.productId === undefined) {
      return Swal.fire({
        icon: "error",
        title: translate("resources.root.selecteProduct"),
      });
    }

    const existingValueIndex = storeTransferOrders.findIndex(
      (order) => order?.productId === newObject?.productId
    );

    if (existingValueIndex === -1) {
      setStoreTransferOrders((prevOrders) => [...prevOrders, newObject]);
      setSelectedProduct(null);
      setQty(0);
      setsmallMeasureQty(0);
    } else {
      const tempOrders = storeTransferOrders;
      tempOrders.map((order, index) => {
        if (index === existingValueIndex) {
          order.qte += newObject.qte;
          order.smallMeasureQte += newObject.smallMeasureQte;
        }
      });
      setStoreTransferOrders(tempOrders);
      setSelectedProduct(null);
      setQty(0);
      setsmallMeasureQty(0);
    }
  };

  const handleRemoveOrder = (productIdToRemove) => {
    const updatedOrders = storeTransferOrders.filter(
      (order) => order.productId !== productIdToRemove
    );
    setStoreTransferOrders(updatedOrders);
  };

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Products?_end=100&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((product) => {
        newArray.push({
          value: product?.id,
          product: product,
        });
      });
      callback(newArray);
    }
  };

  React.useEffect(() => {
    HTTP.get(`${url}/Stores?_end=10000`).then((res) => {
      setStores(res.data);
    });
    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });
  }, []);
  return (
    <Create {...props} redirect="list" className="">
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-between align-items-center w-100">
          <Autocomplete
            className="col-12 col-md-6 col-lg-4"
            options={stores}
            getOptionLabel={(option) => `${option.title ?? ""}`}
            style={{ width: 300 }}
            onChange={(e, val) => {
              setGeneralData((prevState) => ({
                ...prevState,
                storeId: val?.id,
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={translate("resources.root.fromStore")}
                variant="outlined"
              />
            )}
          />
          <Autocomplete
            className="col-12 col-md-6 col-lg-4"
            options={stores}
            getOptionLabel={(option) => `${option.title ?? ""}`}
            style={{ width: 300 }}
            onChange={(e, val) => {
              setGeneralData((prevState) => ({
                ...prevState,
                toStoreId: val?.id,
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={translate("resources.root.toStore")}
                variant="outlined"
              />
            )}
          />
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center align-items-center border border-secondary p-0 rounded bg-secondary">
            <div className="">
              <Form.Label className="m-0 text-nowrap p-1 px-2 text-white">
                {translate("resources.root.date")}
              </Form.Label>{" "}
            </div>
            <Form.Control
              className="p-1 "
              type="date"
              value={generalData.date}
              onChange={(e) => {
                setGeneralData((prevState) => ({
                  ...prevState,
                  date: e.target.value,
                }));
              }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100">
          <TextField
            style={{ width: 300 }}
            className="col-12 col-md-6 col-lg-4 px-2"
            label={translate("resources.root.driver")}
            variant="outlined"
            onChange={(e) =>
              setGeneralData((prevState) => ({
                ...prevState,
                driver: e.target.value,
              }))
            }
          />
          <TextField
            style={{ width: 300 }}
            className="col-12 col-md-6 col-lg-4 px-2"
            label={translate("resources.root.transferredBy")}
            variant="outlined"
            onChange={(e) =>
              setGeneralData((prevState) => ({
                ...prevState,
                transferdBy: e.target.value,
              }))
            }
          />
          <TextField
            style={{ width: 300 }}
            className="col-12 col-md-6 col-lg-4 px-2"
            label={translate("resources.root.note")}
            variant="outlined"
            onChange={(e) =>
              setGeneralData((prevState) => ({
                ...prevState,
                note: e.target.value,
              }))
            }
          />
        </div>
        <hr />
        {/* Adding New Product Region */}
        <div className="row justify-content-center align-items-center w-100 mt-4">
          {selectedProduct && (
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="rounded-pill p-1 px-3 mx-2 bg-primary text-white">
                <p className="p-0 m-0">
                  {translate("resources.root.productMeasure")} -{" "}
                  {selectedProduct.productMeasure}{" "}
                </p>
              </div>
              <div className="rounded-pill p-1 px-3 mx-2 bg-primary text-white">
                <p className="p-0 m-0">
                  {translate("resources.root.oneMeasure")} -{" "}
                  {selectedProduct.oneMeasure}{" "}
                </p>
              </div>
              <div className="rounded-pill p-1 px-3 mx-2 bg-primary text-white">
                <p className="p-0 m-0">
                  {translate("resources.root.oneContains")} -{" "}
                  {selectedProduct.oneContains}{" "}
                </p>
              </div>
              <div className="rounded-pill p-1 px-3 mx-2 bg-primary text-white">
                <p className="p-0 m-0">
                  {translate("resources.root.sellPrice")} -{" "}
                  {selectedProduct.sellByMain
                    ? `$${selectedProduct.mcSellPrice}`
                    : `${selectedProduct.scSellPrice} IQD`}
                </p>
              </div>
              <div className="rounded-pill p-1 px-3 mx-2 bg-primary text-white">
                <p className="p-0 m-0">
                  {translate("resources.root.purchasePrice")} -{" "}
                  {selectedProduct.purchaseByMain
                    ? `$${selectedProduct.mcPurchasePrice}`
                    : `${selectedProduct.scPurchasePrice} IQD`}
                </p>
              </div>
            </div>
          )}
          {localStorage.getItem("useOldSelect") === "true" ? (
            <Autocomplete
              className="col-12 col-md-3 mt-4"
              options={products}
              getOptionLabel={(option) => `${option.name} - ${option.barcode}`}
              style={{ width: 300 }}
              onChange={(e, val) => {
                setSelectedProduct(val);
                setQty(0);
                setsmallMeasureQty(0);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translate("resources.root.product")}
                  variant="outlined"
                />
              )}
            />
          ) : (
            <AsyncSelect
              defaultOptions
              className="col-6 col-md-3 mt-4 w-100"
              loadOptions={loadOptions}
              getOptionLabel={(option) =>
                `${option.product.name} - ${option.product.barcode}`
              }
              onChange={(e, val) => {
                setSelectedProduct(e.product);
                setQty(0);
                setsmallMeasureQty(0);
              }}
            />
          )}

          <TextField
            style={{ width: 300 }}
            type="number"
            className="col-6 col-md-3 mt-4 px-2"
            label={translate("resources.root.qty")}
            variant="outlined"
            name="qty"
            value={qty}
            onChange={(e) => {
              if (e.target.value < 0 || !e.target.value) {
                return setQty(0);
              }
              setQty(parseFloat(e.target.value));
            }}
          />

          <TextField
            style={{ width: 300 }}
            type="number"
            className="col-6 col-md-3 mt-4 px-2"
            label={translate("resources.root.smallQty")}
            variant="outlined"
            name="totalSmallSentQte"
            value={smallMeasureQty}
            onChange={(e) => {
              if (e.target.value < 0 || !e.target.value) {
                return setsmallMeasureQty(0);
              }
              setsmallMeasureQty(parseFloat(e.target.value));
            }}
            onBlur={(e) => {
              let currentValue = parseFloat(e.target.value);

              if (currentValue >= selectedProduct.oneContains) {
                setsmallMeasureQty(currentValue % selectedProduct.oneContains);
                setQty(
                  parseFloat(qty) +
                    parseInt(currentValue / selectedProduct.oneContains)
                );
              }
            }}
          />

          <div className="col-6 col-md-1 px-2 mt-4">
            <button
              type="button"
              className="btn btn-success btn-block"
              onClick={() => {
                handlePushValue({
                  productId: selectedProduct?.id,
                  productName: selectedProduct?.name,
                  productBarcode: selectedProduct?.barcode,
                  productQteInStock: selectedProduct?.qteInStock,
                  qte: qty,
                  smallMeasureQte: smallMeasureQty,
                });
              }}
            >
              {translate("resources.root.add")}
            </button>
          </div>
        </div>

        <hr />
        <table className="table table-striped text-center">
          <thead>
            <tr>
              <th scope="col">{translate("resources.root.name")}</th>
              <th scope="col">{translate("resources.root.barcode")}</th>
              <th scope="col">{translate("resources.root.qteInStock")}</th>
              <th scope="col">{translate("resources.root.sendQty")}</th>
              <th scope="col">{translate("resources.root.totalSmallQte")}</th>
              <th scope="col">{translate("resources.root.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {storeTransferOrders?.map((order) => {
              return (
                <tr key={order?.productId}>
                  <th>{order?.productName}</th>
                  <th>{order?.productBarcode}</th>
                  <td>{order?.productQteInStock}</td>
                  <td>{order?.qte}</td>
                  <td>{order?.smallMeasureQte}</td>
                  <td
                    onClick={() => handleRemoveOrder(order?.productId)}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-trash3 btn btn-danger p-1 "
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                    </svg>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <button
          onClick={async (e) => {
            const swalWithBootstrapButtons = Swal.mixin({
              customClass: {
                confirmButton: "btn btn-success mx-1 ",
                cancelButton: "btn btn-danger mx-1 ",
              },
              buttonsStyling: false,
            });
            swalWithBootstrapButtons
              .fire({
                title: translate("resources.root.swalTitle"),
                text: translate("resources.root.swalTransferProduct"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: translate("resources.root.yes"),
                cancelButtonText: translate("resources.root.no"),
              })
              .then((result) => {
                if (result.isConfirmed) {
                  [generalData.storeTransferOrders] = [storeTransferOrders];
                  HTTP.post(`/StoreTransferInvoices`, generalData)
                    .then((res) => {
                      setGeneralData({
                        storeId: 0,
                        toStoreId: 0,
                        driver: null,
                        transferdBy: null,
                        note: null,
                      });
                      setStoreTransferOrders([]);
                    })
                    .then(() => {
                      swalWithBootstrapButtons.fire({
                        title: translate("resources.root.swalSuccess"),
                        icon: "success",
                      });
                      console.log("scheduled");
                      navigate("StoreTransferInvoices");
                    })
                    .catch((err) => handleRequestResponse(notify, err));
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                ) {
                }
              });
          }}
          className="btn btn-primary btn-block mt-5"
        >
          {translate("resources.root.submit")}
        </button>
      </div>
    </Create>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 72,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  dateTimeInput: {
    width: "100% !important",
    margin: "0 10px -35px",
  },
  addressInput: {
    width: "70% !important",
    marginTop: 8,
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
    margin: "0 10px",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "350px !important",
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  successChips: {
    backgroundColor: "#72ffa7",
    color: "#000",
  },
  printWrapper: {
    display: "none",
    width: "100%",
    height: "950",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
}));
