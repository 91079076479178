import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  ReferenceField,
  maxValue,
  FormDataConsumer,
  DateField,
  required,
  FunctionField,
  NumberInput,
  minValue,
  DateInput,
  useRedirect,
  BooleanInput,
  AutocompleteInput,
  useTranslate,
  Show,
  SimpleShowLayout,
  ShowButton,
} from "react-admin";
import { ListActions } from "../templates/ListActions";
import { url, handleRequestResponse } from "../../request";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button } from "react-bootstrap";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/VoucherReceipt/Page";
import { HTTP } from "../../axios";

import LinearProgress from "@mui/material/LinearProgress";

import { useParams, useLocation } from "react-router-dom";

export const EmployeeLoansList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const translate = useTranslate();

  return (
    <List
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate="hr.list"
        />
      }
    >
      <Datagrid
        bulkActionButtons={<BulkAction {...props} hasDelete="hr.list" />}
      >
        <ReferenceField
          label="resources.root.employee"
          source="employeeId"
          reference="Employees"
        >
          <FunctionField
            label="resources.root.name"
            render={(record) => `${record.firstName} ${record.middleName}`}
          />
        </ReferenceField>
        {localStorage.getItem("showInvoiceNumber") === "true" && (
          <TextField
            source="invoiceNumber"
            label="resources.root.invoiceNumber"
          />
        )}
        {currency === "default" && (
          <FunctionField
            label="resources.root.currentLoan"
            sortBy={"mcPrice"}
            render={(record) =>
              record.byMain
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.currentLoan"
            sortBy={"mcPrice"}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        <TextField source="accountant" label="resources.root.accountant" />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const EmployeeLoansCreate = (props) => {
  const [choices, setChoices] = React.useState([]);
  const [currency, setCurrency] = React.useState(false);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [employeeId, setEmployeeId] = React.useState(0);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [loans, setLoans] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();

  const classes = useStyles();
  const translate = useTranslate();
  const redirect = useRedirect();

  React.useEffect(() => {
    HTTP.get(`${url}/Employees?_end=1000`).then((res) => {
      setChoices(res.data);
    });
  }, []);

  React.useEffect(() => {
    if (employeeId === 0) return;
    HTTP.get(`${url}/Employees/${employeeId}`).then((res) => {
      setSelectedEmployee(res.data);
      setLoans(res.data?.employeeLoans);
      setCurrency(res.data?.loanByMain);
      setLoading(false);
    });
  }, [employeeId]);

  const optionRenderer = (choice) => `${choice.firstName} ${choice.middleName}`;
  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        accountant: localStorage.getItem("name"),
        byMain: currency,
      })}
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="employeeId"
              perPage={1000}
              label="resources.root.chooseEmployee"
              choices={choices}
              optionText={optionRenderer}
              optionValue="id"
              validate={[required()]}
              onChange={(val) => {
                setEmployeeId(val);
                setSeparatedNumber(0);
                setLoading(true);
              }}
              name={`employeeId`}
            />
            {currency ? (
              <NumberInput
                label="resources.root.mcLoanPrice"
                validate={[
                  required(),
                  minValue(0),
                  maxValue(selectedEmployee?.mcRoofLoan),
                ]}
                source="mcPrice"
                defaultValue={0}
                onChange={(e) =>
                  setSeparatedNumber(parseFloat(e.target.value) || 0)
                }
              />
            ) : (
              <NumberInput
                label="resources.root.scLoanPrice"
                validate={[
                  required(),
                  minValue(0),
                  maxValue(selectedEmployee?.scRoofLoan),
                ]}
                source="scPrice"
                defaultValue={0}
                onChange={(e) =>
                  setSeparatedNumber(parseFloat(e.target.value) || 0)
                }
              />
            )}
            <DateInput
              source="date"
              label="resources.root.date"
              defaultValue={moment().format("YYYY-MM-DD")}
            />

            {/* <BooleanInput
              source="byMain"
              className={`classes.input `}
              label={
                currency ? "resources.root.loanUsd" : "resources.root.loanIqd"
              }
              defaultValue={currency}
              onChange={(e) => setCurrency(e.target.checked)}
            /> */}

            <TextInput source="note" label="resources.root.note" multiline />
            {selectedEmployee?.id && selectedEmployee?.mcRoofLoan <= 0 && (
              <div className="d-flex justify-content-start align-items-center ">
                <p className="p-0 m-0">
                  {translate("resources.root.changeTheRoofLoan")}:
                </p>
                <Button
                  variant="primary"
                  className="px-5 mx-2"
                  onClick={() => {
                    redirect(
                      `../../Employees/${selectedEmployee.id}?fromRoute=${location.pathname}`
                    );
                  }}
                >
                  {translate("resources.root.visitchange")}
                </Button>
              </div>
            )}
          </div>
          <div className={classes.formCol}>
            {
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.loanMaxLoan")}
                  </Typography>
                  {selectedEmployee && (
                    <Typography
                      className={classes.marginBottom}
                      variant="h5"
                      component="h2"
                    >
                      {selectedEmployee.salaryByMain
                        ? `$${selectedEmployee.mcCurrentLoan?.toLocaleString()}`
                        : `${selectedEmployee.scCurrentLoan?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`}
                      {` - ${
                        selectedEmployee.salaryByMain
                          ? `$${selectedEmployee.mcRoofLoan?.toLocaleString()}`
                          : `${selectedEmployee.scRoofLoan?.toLocaleString()} ${translate(
                              "resources.root.iqd"
                            )}`
                      }`}
                    </Typography>
                  )}
                  {selectedEmployee && (
                    <Typography
                      className={classes.title}
                      style={{
                        color:
                          (selectedEmployee.salaryByMain
                            ? selectedEmployee.mcCurrentLoan + separatedNumber <
                                0 && "red"
                            : selectedEmployee.scCurrentLoan + separatedNumber <
                                0 && "red") ||
                          (selectedEmployee.salaryByMain
                            ? selectedEmployee.mcCurrentLoan + separatedNumber >
                                selectedEmployee.mcRoofLoan && "red"
                            : selectedEmployee.scCurrentLoan + separatedNumber >
                                selectedEmployee.scRoofLoan && "red"),
                      }}
                      color="textSecondary"
                      gutterBottom
                    >
                      {translate("resources.root.currentLoan")}
                    </Typography>
                  )}
                  {selectedEmployee && (
                    <Typography
                      className={classes.marginBottom}
                      style={{
                        color:
                          (selectedEmployee.salaryByMain
                            ? selectedEmployee.mcCurrentLoan + separatedNumber <
                                0 && "red"
                            : selectedEmployee.scCurrentLoan + separatedNumber <
                                0 && "red") ||
                          (selectedEmployee.salaryByMain
                            ? selectedEmployee.mcCurrentLoan + separatedNumber >
                                selectedEmployee.mcRoofLoan && "red"
                            : selectedEmployee.scCurrentLoan + separatedNumber >
                                selectedEmployee.scRoofLoan && "red"),
                      }}
                      variant="h5"
                      component="h2"
                    >
                      {selectedEmployee.salaryByMain
                        ? `$${(
                            selectedEmployee.mcCurrentLoan + separatedNumber
                          )?.toLocaleString()}`
                        : `${(
                            selectedEmployee.scCurrentLoan + separatedNumber
                          )?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`}
                    </Typography>
                  )}
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {selectedEmployee && translate("resources.root.employee")}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {selectedEmployee && selectedEmployee.phone}
                    <br />
                    {selectedEmployee && selectedEmployee.email}
                    <br />
                    {/* {translate("resources.root.gender")} :{" "}
                    {selectedEmployee && selectedEmployee.Male
                      ? translate("resources.root.male")
                      : translate("resources.root.female")}
                    <br /> */}
                    {translate("resources.root.address")} :{" "}
                    {selectedEmployee && selectedEmployee.address}
                    <br />
                    {translate("resources.root.salaryType")} :{" "}
                    {selectedEmployee && selectedEmployee.salaryType}
                  </Typography>
                </CardContent>
                {loading && <LinearProgress className={classes.progressBar} />}
              </Card>
            }
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const EmployeeLoansShow = (props) => {
  const [mainColor, SetMainColor] = React.useState(
    localStorage.getItem("mainColorEmployeeLoan") !== null
      ? localStorage.getItem("mainColorEmployeeLoan")
      : "#14a44d"
  );
  localStorage.setItem("mainColorEmployeeLoan", mainColor);

  const translate = useTranslate();
  const [payment, setPayment] = React.useState([]);
  const { id } = useParams();
  React.useEffect(() => {
    HTTP.get(`${url}/EmployeeLoans/${id}`).then((res) => {
      setPayment(res.data);
    });
  }, [props]);
  return (
    <div>
      <Page
        data={{
          invoiceNo: payment.invoiceNumber && payment.invoiceNumber,
          serialNo: payment.id && payment.id,
          dollar: payment.byMain && payment.byMain,
          isDeposit: false,
          date: payment.date && payment.date,
          payedTo:
            payment.employee &&
            `${payment.employee.firstName} ${payment.employee.middleName} ${payment.employee.lastName}`,
          amount: (payment?.byMain ? payment?.mcPrice : payment?.scPrice) || 0,
          currentLoan:
            (payment?.employee?.loanByMain
              ? payment?.employee?.mcCurrentLoan
              : payment?.employee?.scCurrentLoan) || 0,
          type: "EmployeeLoan",
          note: payment?.note || "",
        }}
      />
      <Show>
        <SimpleShowLayout>
          <FunctionField
            label="resources.root.employee"
            render={(record) =>
              record.employee &&
              `${record.employee.firstName} ${record.employee.middleName} ${record.employee.lastName}`
            }
          />
          <FunctionField
            label="resources.root.loan"
            render={(record) =>
              record.byMain
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
          <TextField source="payedBy" label="resources.root.payedBy" />
          <TextField source="accountant" label="resources.root.accountant" />
          <DateField
            source="date"
            label="resources.root.date"
            locales={"en-GB"}
          />
          <TextField source="note" label="resources.root.note" />
          <div className="d-flex justify-content-start align-items-center">
            <Button
              class={`btn btn-primary`}
              onClick={() => {
                let appLayout = document.getElementsByClassName("theRoot");
                let sidebar = document.getElementsByClassName("MuiDrawer-root");
                let appMenu = document.getElementsByClassName("MuiPaper-root");
                let appBar = document.getElementsByClassName("theRoot");
                let showPage = document.getElementsByClassName("show-page");
                let printInvoice = document.getElementById("hidePage");
                printInvoice.classList.remove("d-none");
                appLayout[0].style.marginTop = "0";
                sidebar[0].style.display = "none";
                appMenu[0].style.display = "none";
                appBar[0].style.marginTop = "0px";
                showPage[0].style.display = "none";
                window.print();
                printInvoice.classList.add("d-none");
                appLayout[0].style.marginTop = "10px";
                sidebar[0].style.display = "block";
                appMenu[0].style.display = "block";
                appBar[0].style.marginTop = "40px";
                showPage[0].style.display = "block";
              }}
            >
              {translate("resources.root.print")}
            </Button>
            {process.env.REACT_APP_APP_NAME === "Sherko" && (
              <div className="mx-4 d-flex justify-content-center align-items-center">
                <p className="m-0 p-0 ">
                  {translate("resources.root.selectColorVoucher")}:&nbsp;
                </p>
                <div className="cp_wrapper">
                  <input
                    className="input-voucher"
                    type="color"
                    value={mainColor}
                    onChange={(e) => SetMainColor(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
        </SimpleShowLayout>
      </Show>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "49%",
  },
  input: {
    width: "100%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
  root: {
    minWidth: 275,
    marginTop: 6,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  marginBottom: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    marginBottom: 0,
  },
  pos: {
    marginBottom: 12,
  },
  primary: {
    backgroundColor: "#1976d2",
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  btnGroup: {
    marginRight: 10,
  },
});
