import * as React from "react";
import { useRedirect, useNotify, useTranslate, DateInput } from "react-admin";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { url } from "../../request";
import MaterialTextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import Chip from "@mui/material/Chip";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiAlert from "@mui/lab/Alert";
import Snackbar from "@mui/material/Snackbar";
import swal from "sweetalert";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { Form } from "react-bootstrap";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "./../../assets/app.css";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import AddTaskIcon from "@mui/icons-material/AddTask";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";

export const ScPurchaseInvoiceNew = ({ invoice }) => {
  const _ = require("lodash");

  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const [suppliers, setSuppliers] = React.useState([]);
  const [previousPurchasePrice, setPreviousPurchasePrice] =
    React.useState(null);
  const [selectedSupplier, setSelectedSupplier] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [cashPayment, setCashPayment] = React.useState(false);
  const [invoiceNumber, setInvoiceNumber] = React.useState(null);
  const [orderQtt, setOrderQtt] = React.useState(1);
  const [productSerial, setProductSerial] = React.useState(null);
  const [productSerialExpire, setProductSerialExpire] = React.useState(
    moment(new Date()).add(6, "M")
  );
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [orders, setOrders] = React.useState(
    invoice === "return"
      ? localStorage.getItem("scPurchaseReturnOrders")
        ? JSON.parse(localStorage.getItem("scPurchaseReturnOrders"))
        : []
      : localStorage.getItem("scPurchaseOrders")
      ? JSON.parse(localStorage.getItem("scPurchaseOrders"))
      : []
  );
  const idemPotent = uuidv4();
  const [discount, setDiscount] = React.useState(0);
  const [percentageDiscount, setPercentageDiscount] = React.useState(0);
  const [discountInPercent, setDiscountInPercent] = React.useState(true);
  const [modifiedPriceInIQD, setModifiedPriceInIQD] = React.useState(0);
  const [basePurchasePriceInIQD, setBasePurchasePriceInIQD] = React.useState(0);
  const [orderDiscount, setOrderDiscount] = React.useState(0);
  const [orderDiscountPercentage, setOrderDiscountPercentage] =
    React.useState(0);
  const [orderDiscountInPercent, setOrderDiscountInPercent] =
    React.useState(true);
  const [gift, setGift] = React.useState(0);
  const [productNote, setProductNote] = React.useState("");
  const [driver, setDriver] = React.useState("");
  const [orderToEdit, setOrderToEdit] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [delay, setDelay] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [jss, setJss] = React.useState(
    create({ plugins: [...jssPreset().plugins, rtl()] })
  );

  const [tax, setTax] = React.useState(localStorage.getItem("scTax") || 0);
  const [other, setOther] = React.useState(
    localStorage.getItem("scOther") || 0
  );
  const [transport, setTransport] = React.useState(
    localStorage.getItem("scTransport") || 0
  );

  localStorage.setItem("scTax", tax);
  localStorage.setItem("scOther", other);
  localStorage.setItem("scTransport", transport);
  const [isActiveDisBut, setIsActiveDisBut] = React.useState(false);

  const redirect = useRedirect();

  React.useEffect(() => {
    HTTP.get(`${url}/Suppliers?_end=1000&byMain=false`).then((res) => {
      setSuppliers(res.data);
    });
    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });
  }, []);

  React.useEffect(() => {
    selectedProduct &&
      selectedSupplier &&
      HTTP.get(
        `${url}/PurchaseInvoices/GetLastPriceOfProduct?supplierId=${selectedSupplier.id}&productId=${selectedProduct.id}`
      ).then((res) => {
        setPreviousPurchasePrice(res.data);
      });
  }, [selectedSupplier, selectedProduct]);

  React.useEffect(() => {
    if (invoice === "return") {
      localStorage.removeItem("scPurchaseReturnOrders");
      localStorage.setItem("scPurchaseReturnOrders", JSON.stringify(orders));
    } else {
      localStorage.removeItem("scPurchaseOrders");
      localStorage.setItem("scPurchaseOrders", JSON.stringify(orders));
    }
  }, [orders]);
  const latestPriceModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid transparent",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: "10px",
  };

  React.useEffect(() => {
    selectedProduct && (selectedProduct.scPurchasePrice = modifiedPriceInIQD);
    selectedProduct &&
      (selectedProduct.scBasePurchasePrice = basePurchasePriceInIQD);
  }, [modifiedPriceInIQD, basePurchasePriceInIQD]);

  React.useEffect(() => {
    setSelectedProduct(products.filter((el) => el.id === orderToEdit)[0]);
  }, [orderToEdit]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function roundToTwo(num) {
    return +(Math.round(num + "e+3") + "e-3");
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [latestpriceModal, setLatestpriceModal] = React.useState(false);

  const handleCloseLatestPriceModal = () => {
    setLatestpriceModal(false);
  };

  const [latestPrices, setLatestPrice] = React.useState([]);
  const openLatestProductPrice = () => {
    if (!selectedProduct) return;
    HTTP.get(
      `/Reports/ProductAccountStatement/${selectedProduct?.id}?type=Purchase`
    ).then((response) => {
      setLatestPrice(response.data);
      setLatestpriceModal(true);
    });
  };
  const handleGiftDistribute = () => {
    var newQty = parseFloat(orderQtt) + parseFloat(gift);
    var totalScPurchasePrice =
      parseFloat(orderQtt) * parseFloat(modifiedPriceInIQD);
    var newPriceOfPurchase = totalScPurchasePrice / newQty;
    setOrderQtt(newQty);
    setBasePurchasePriceInIQD(parseFloat(modifiedPriceInIQD));
    setModifiedPriceInIQD(newPriceOfPurchase);
    setGift(0);
  };

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let response = await HTTP.get(
        `${url}/Products?_end=1000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((product) => {
        newArray.push({
          value: product.id,
          name: product.name,
          brandName: product.brandName,
          barcode: product.barcode,
          product: product,
        });
      });
      callback(newArray);
    }
  };

  const distributeTaxOtherTransport = (orders) => {
    orders.map((el) => {
      el.scPurchasePrice =
        (el.scBasePurchasePrice * el.qte -
          el.scBasePurchasePrice * el.qte * (percentageDiscount / 100)) /
        el.qte;
      el.scTotalPrice = el.qte * el.scPurchasePrice;
    });

    let AddedPrice =
      parseFloat(tax) + parseFloat(other) + parseFloat(transport);

    let totalPrice = _.sumBy(orders, (el) => {
      return el.scTotalPrice;
    });

    orders.map((el) => {
      let newItemPrice =
        (((parseFloat(AddedPrice) + totalPrice) / totalPrice) *
          el.scTotalPrice) /
        el.qte;
      el.scPurchasePrice = newItemPrice;
      el.scTotalPrice = el.qte * el.scPurchasePrice;
    });
  };

  const changeCol = (id, newValue, col) => {
    let selectedOrder = orders.find((el) => el.productId === id);
    let selectedOrderIndex = orders.indexOf(selectedOrder);

    selectedOrder[col] = newValue;
    selectedOrder.scTotalPrice =
      selectedOrder.qte * selectedOrder.scPurchasePrice;

    let newOrders = orders.filter((x) => x.productId !== id);

    newOrders = [
      ...newOrders.slice(0, selectedOrderIndex),
      selectedOrder,
      ...newOrders.slice(selectedOrderIndex),
    ];
    setOrders(newOrders);
  };

  return (
    <StylesProvider jss={jss}>
      <Card className={classes.root}>
        <CardContent>
          <div className={classes.invoice}>
            <div className={classes.invoiceCols}>
              <Autocomplete
                id="combo-box-demo"
                options={suppliers}
                getOptionLabel={(option) =>
                  `${option.supplierName} (${option.phone})`
                }
                onChange={(el, val) => {
                  setDelay(false);
                  setSelectedSupplier(val);
                  setInvoiceNumber(val.invoiceNumber ?? null);
                }}
                renderInput={(params) => (
                  <MaterialTextField
                    {...params}
                    error={submitAttempted && !selectedSupplier}
                    label={translate("resources.root.chooseSupplier")}
                    variant="outlined"
                  />
                )}
                className={classes.autocomplete}
              />
              <MaterialTextField
                label={translate("resources.root.invoiceNumber")}
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
                className={classes.invoiceNumberInput}
              />
              <Form.Group className={`m-4`}>
                <Datetime
                  initialValue={selectedDate}
                  defaultShow={true}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm:ss"
                  onChange={(e) => {
                    setSelectedDate(e.format("YYYY-MM-DD HH:mm:ss"));
                  }}
                />
              </Form.Group>
            </div>

            <div className={classes.invoiceCols}>
              <Chip
                icon={<PhoneIphoneIcon />}
                label={
                  selectedSupplier
                    ? selectedSupplier.phone
                    : translate("resources.root.phone")
                }
                className={classes.bottomLeftMargin}
              />
              <Chip
                label={
                  selectedSupplier
                    ? `${translate("resources.root.currentLoan")} ${
                        selectedSupplier.payLoanByMain
                          ? selectedSupplier.mcCurrentLoan?.toLocaleString()
                          : selectedSupplier.scCurrentLoan?.toLocaleString()
                      } ${
                        selectedSupplier.payLoanByMain
                          ? translate("resources.root.usd")
                          : translate("resources.root.iqd")
                      }`
                    : translate("resources.root.currentLoan")
                }
                className={classes.bottomLeftMargin}
              />
              <Chip
                label={
                  selectedSupplier
                    ? `${translate("resources.root.maxLoan")} ${
                        selectedSupplier.payLoanByMain
                          ? selectedSupplier.mcRoofLoan?.toLocaleString()
                          : selectedSupplier.scRoofLoan?.toLocaleString()
                      } ${
                        selectedSupplier.payLoanByMain
                          ? translate("resources.root.usd")
                          : translate("resources.root.iqd")
                      }`
                    : translate("resources.root.maxLoan")
                }
                className={classes.bottomLeftMargin}
              />
              <Chip
                label={
                  selectedSupplier
                    ? `${translate("resources.root.futureLoan")} ${(
                        roundToTwo(
                          selectedSupplier.payLoanByMain
                            ? parseFloat(selectedSupplier.mcCurrentLoan)
                            : parseFloat(selectedSupplier.scCurrentLoan)
                        ) +
                        (invoice === "return" ? -1 : 1) *
                          (!cashPayment
                            ? parseFloat(
                                orders
                                  .map(
                                    (el) =>
                                      parseFloat(el.scPurchasePrice) * el.qte
                                  )
                                  .reduce((a, b) => a + b, 0) - discount
                              )
                            : 0)
                      )?.toLocaleString()} ${
                        selectedSupplier.payLoanByMain
                          ? translate("resources.root.usd")
                          : translate("resources.root.iqd")
                      }`
                    : translate("resources.root.futureLoan")
                }
                className={classes.bottomLeftMargin}
              />
            </div>
          </div>
          <div className={classes.invoice}>
            <div className={classes.toggles}>
              {!invoice && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={cashPayment}
                      onChange={(e) => {
                        setCashPayment(e.target.checked);
                        setDelay(false);
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={translate("resources.root.cash")}
                />
              )}
            </div>
          </div>
          <div className={classes.orders}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <div className={classes.orderCols}>
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.productMeasure")} - ${
                              selectedProduct.productMeasure
                            }`
                          : translate("resources.root.productMeasure")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.oneMeasure")} - ${
                              selectedProduct.oneMeasure
                            }`
                          : translate("resources.root.oneMeasure")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.oneContains")} ${
                              selectedProduct.oneContains
                            } ${translate("resources.root.items")}`
                          : translate("resources.root.oneContains")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.totalPrice")} - ${
                              modifiedPriceInIQD > 0
                                ? roundToTwo(modifiedPriceInIQD * orderQtt)
                                : roundToTwo(
                                    selectedProduct.scPurchasePrice * orderQtt
                                  )
                            }  ${translate("resources.root.iqd")}`
                          : translate("resources.root.totalPrice")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.currency")} - ${
                              selectedProduct.sellByMain
                                ? translate("resources.root.usd")
                                : translate("resources.root.iqd")
                            }`
                          : translate("resources.root.currency")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    {previousPurchasePrice && (
                      <Chip
                        label={
                          selectedProduct &&
                          previousPurchasePrice.scPurchasePrice &&
                          `${previousPurchasePrice.scPurchasePrice?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`
                        }
                        className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                        style={{
                          backgroundColor: "lightgreen",
                          color: "black",
                          marginLeft: "auto",
                        }}
                      />
                    )}
                  </div>
                  <div className={classes.orderCols}>
                    <div style={{ width: "350px" }}>
                      {localStorage.getItem("useOldSelect") === "true" ? (
                        <Autocomplete
                          id="combo-box-demo"
                          options={products}
                          // inputValue={selectedProduct ? selectedProduct.name : ''}
                          getOptionLabel={(option) =>
                            `${option.name} - ${option.barcode} (${option.brandName})`
                          }
                          style={{ width: 300 }}
                          onChange={(el, val) => {
                            setSelectedProduct(val);
                            setOrderQtt(1);
                            setModifiedPriceInIQD(
                              val ? val.scPurchasePrice : 0
                            );
                            setBasePurchasePriceInIQD(
                              val ? val.scPurchasePrice : 0
                            );
                          }}
                          renderInput={(params) => (
                            <MaterialTextField
                              {...params}
                              label={translate("resources.root.chooseProduct")}
                              variant="outlined"
                            />
                          )}
                        />
                      ) : (
                        <AsyncSelect
                          defaultOptions
                          menuPortalTarget={document.body}
                          loadOptions={loadOptions}
                          getOptionLabel={(option) =>
                            `${option.name} - ${option.barcode} (${option.brandName})`
                          }
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          onChange={(e) => {
                            setSelectedProduct(e.product);
                            setOrderQtt(1);
                            setModifiedPriceInIQD(
                              e.product ? e.product.scPurchasePrice : 0
                            );
                            setBasePurchasePriceInIQD(
                              e.product ? e.product.scPurchasePrice : 0
                            );
                          }}
                        />
                      )}
                    </div>
                    {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                      <MaterialTextField
                        label={translate("resources.root.serial")}
                        defaultValue={productSerial}
                        onChange={(e) => {
                          setProductSerial(e.target.value);
                        }}
                        className={classes.orderNumberInput}
                      />
                    )}
                    {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                      <Form.Group className={`m-4`}>
                        <Datetime
                          initialValue={moment(new Date()).add(6, "M")}
                          defaultShow={true}
                          timeFormat={false}
                          dateFormat="YYYY-MM-DD"
                          onChange={(e) => {
                            setProductSerialExpire(
                              e.format("YYYY-MM-DD HH:mm:ss")
                            );
                          }}
                        />
                      </Form.Group>
                    )}
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.quantity")}
                      disabled={!selectedProduct}
                      value={orderQtt}
                      onFocus={(event) => event.target.select()}
                      onChange={(e) => {
                        setOrderQtt(e.target.value);
                      }}
                      className={classes.orderNumberInput}
                    />
                    <MaterialTextField
                      label={translate("resources.root.max")}
                      disabled={true}
                      value={selectedProduct ? selectedProduct.qteInStock : 0}
                      className={classes.orderNumberInput}
                    />
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.priceInIQD")}
                      value={modifiedPriceInIQD}
                      onChange={(e) => {
                        setModifiedPriceInIQD(parseFloat(e.target.value));
                        setBasePurchasePriceInIQD(parseFloat(e.target.value));
                      }}
                      className={classes.orderNumberInput}
                    />
                    <FormControl
                      sx={{ m: 1, width: "25ch" }}
                      variant="filled"
                      inputProps={{ tabIndex: "-1" }}
                    >
                      <InputLabel
                        inputProps={{ tabIndex: "-1" }}
                        htmlFor="filled-adornment-password"
                      >
                        {translate("resources.root.gifts")}
                      </InputLabel>
                      <FilledInput
                        inputProps={{ tabIndex: "-1" }}
                        id="filled-adornment-password"
                        type={"number"}
                        disabled={!selectedProduct}
                        value={gift}
                        defaultValue={gift}
                        onChange={(e) => {
                          parseFloat(e.target.value) +
                            orders
                              .filter(
                                (el) => el.productId === selectedProduct.id
                              )
                              .map(
                                (el) =>
                                  parseFloat(el.qte) + parseFloat(el.giftQte)
                              )
                              .reduce(
                                (a, b) => parseFloat(a) + parseFloat(b),
                                0
                              ) +
                            parseFloat(e.target.value) >=
                            0 && setGift(parseFloat(e.target.value));
                        }}
                        endAdornment={
                          <InputAdornment
                            position="end"
                            inputProps={{ tabIndex: "-1" }}
                          >
                            <Tooltip
                              title="Distribute the gift over quantity"
                              inputProps={{ tabIndex: "-1" }}
                            >
                              <IconButton
                                disabled={!selectedProduct}
                                inputProps={{ tabIndex: "-1" }}
                                aria-label="toggle password visibility"
                                onClick={handleGiftDistribute}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                <AddTaskIcon inputProps={{ tabIndex: "-1" }} />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    {/* <MaterialTextField
                      inputProps={{ tabIndex: "-1" }}
                      type="number"
                      label={translate("resources.root.gifts")}
                      disabled={!selectedProduct}
                      value={gift}
                      onChange={(e) => {
                        parseFloat(e.target.value) +
                          orders
                            .filter((el) => el.productId === selectedProduct.id)
                            .map(
                              (el) =>
                                parseFloat(el.qte) + parseFloat(el.giftQte)
                            )
                            .reduce(
                              (a, b) => parseFloat(a) + parseFloat(b),
                              0
                            ) +
                          parseFloat(e.target.value) >=
                          0 && setGift(parseFloat(e.target.value));
                      }}
                      className={classes.orderNumberInput}
                    /> */}
                    {process.env.REACT_APP_ORDER_DISCOUNT === "true" && (
                      <div>
                        <FormControlLabel
                          className={classes.inPercentage}
                          control={
                            <Switch
                              checked={orderDiscountInPercent}
                              onChange={(e) =>
                                setOrderDiscountInPercent(e.target.checked)
                              }
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label={translate("resources.root.inPercentage")}
                        />
                        <MaterialTextField
                          type="number"
                          label={translate("resources.root.discountPer")}
                          disabled={
                            !orderDiscountInPercent ||
                            !selectedProduct ||
                            percentageDiscount > 0
                          }
                          value={orderDiscountPercentage}
                          variant="filled"
                          onChange={(e) => {
                            e.target.value >= 0 &&
                              e.target.value <= 100 &&
                              setOrderDiscountPercentage(e.target.value);
                            setOrderDiscount(
                              (e.target.value / 100) *
                                (modifiedPriceInIQD * orderQtt)
                            );
                          }}
                          className={classes.discount2}
                        />
                        <MaterialTextField
                          type="number"
                          label={translate("resources.root.discount")}
                          disabled={
                            orderDiscountInPercent ||
                            !selectedProduct ||
                            discount > 0
                          }
                          value={orderDiscount}
                          variant="filled"
                          onChange={(e) => {
                            e.target.value >= 0 &&
                              setOrderDiscount(e.target.value);
                            setOrderDiscountPercentage(
                              (e.target.value /
                                (modifiedPriceInIQD * orderQtt)) *
                                100
                            );
                          }}
                          className={classes.discount2}
                        />
                      </div>
                    )}
                    <Button
                      style={{ marginLeft: "5px", marginRight: "5px" }}
                      variant="contained"
                      className={classes.botton}
                      disabled={
                        (parseFloat(orderQtt) <= 0 && parseFloat(gift) <= 0) ||
                        !selectedProduct
                      }
                      onClick={() => {
                        (parseFloat(orderQtt) > 0 || parseFloat(gift) > 0) >
                          0 &&
                          setOrders((prev) => {
                            const found = prev.find(
                              (el) =>
                                el.productId === parseInt(selectedProduct.id)
                            );
                            if (found) {
                              const newOrder = prev.map(
                                (el) =>
                                  el.productId === selectedProduct.id && {
                                    productId: selectedProduct.id,
                                    giftQte: el.giftQte + gift,
                                    payByMain: false,
                                    barcode: selectedProduct.barcode,
                                    productSerial: {
                                      serialNumber: productSerial,
                                      expirationDate: productSerialExpire,
                                    },
                                    name: selectedProduct.name,
                                    qte:
                                      parseFloat(orderQtt) +
                                      parseFloat(el.qte ? el.qte : 0),
                                    scLastPrice:
                                      selectedProduct.scPurchasePrice *
                                      (parseFloat(orderQtt) +
                                        parseFloat(el.qte ? el.qte : 0)),
                                    scBasePurchasePrice:
                                      selectedProduct.scBasePurchasePrice,
                                    scPurchasePrice:
                                      selectedProduct.scPurchasePrice,
                                    scBasePurchasePrice:
                                      selectedProduct.scPurchasePrice,
                                    scDiscount: orderDiscount,
                                    scTotalPrice:
                                      modifiedPriceInIQD > 0
                                        ? roundToTwo(modifiedPriceInIQD) *
                                            (parseFloat(orderQtt) +
                                              parseFloat(el.qte ? el.qte : 0)) -
                                          orderDiscount
                                        : selectedProduct.scPurchasePrice *
                                            (parseFloat(orderQtt) +
                                              parseFloat(el.qte ? el.qte : 0)) -
                                          orderDiscount,
                                  }
                              );
                              return [
                                ...prev.filter(
                                  (el) => el.productId !== selectedProduct.id
                                ),
                                ...newOrder.filter((el) => el !== false),
                              ];
                            } else {
                              return [
                                ...prev,
                                {
                                  productId: selectedProduct.id,
                                  giftQte: gift,
                                  scLastPrice:
                                    selectedProduct.scPurchasePrice *
                                    parseFloat(orderQtt),
                                  payByMain: false,
                                  barcode: selectedProduct.barcode,
                                  name: selectedProduct.name,
                                  productSerial: {
                                    serialNumber: productSerial,
                                    expirationDate: productSerialExpire,
                                  },
                                  qte: parseFloat(orderQtt),
                                  scDiscount: orderDiscount,
                                  scBasePurchasePrice:
                                    modifiedPriceInIQD > 0
                                      ? modifiedPriceInIQD
                                      : selectedProduct.scPurchasePrice,
                                  scPurchasePrice:
                                    modifiedPriceInIQD > 0
                                      ? modifiedPriceInIQD
                                      : selectedProduct.scPurchasePrice,
                                  scTotalPrice:
                                    modifiedPriceInIQD > 0
                                      ? modifiedPriceInIQD *
                                          parseFloat(orderQtt) -
                                        orderDiscount
                                      : selectedProduct.scPurchasePrice *
                                          parseFloat(orderQtt) -
                                        orderDiscount,
                                },
                              ];
                            }
                          });

                        setOrderQtt(0);
                        setOrderToEdit(-1);
                        setGift(0);
                        setDelay(false);
                      }}
                    >
                      {translate("resources.root.addOrder")}
                    </Button>{" "}
                    <Modal
                      open={latestpriceModal}
                      onClose={handleCloseLatestPriceModal}
                      aria-labelledby="parent-modal-title"
                      aria-describedby="parent-modal-description"
                      scrollable={true}
                    >
                      <Box
                        className="LatestPriceModal"
                        sx={{
                          ...latestPriceModalStyle,
                          width: 600,
                        }}
                      >
                        <table className="table border table-hover table-striped ">
                          <thead>
                            <td>{translate("resources.root.name")}</td>
                            <td>{translate("resources.root.qty")}</td>
                            <td>{translate("resources.root.price")}</td>
                            <td>{translate("resources.root.date")}</td>
                          </thead>
                          <tbody>
                            {latestPrices.map((price) => {
                              return (
                                <tr>
                                  <td>{price.name}</td>
                                  <td>{price.qte}</td>

                                  <td>
                                    {" "}
                                    {price.byMain
                                      ? price.mcPrice.toLocaleString()
                                      : price.scPrice.toLocaleString()}
                                  </td>
                                  <td>
                                    {moment(price.date).format("YYYY-MM-DD")}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Box>
                    </Modal>
                    <Button
                      variant="contained"
                      className={classes.botton}
                      style={{ marginLeft: 10 }}
                      disabled={!selectedProduct}
                      onClick={openLatestProductPrice}
                    >
                      {translate("resources.root.latestPrice")}
                    </Button>
                  </div>
                  <div className={classes.orderCols}>
                    <TableContainer
                      component={Paper}
                      className={classes.tableContainer}
                    >
                      <Table
                        stickyHeader
                        className={classes.table}
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {translate("resources.root.barcode")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.name")}
                            </TableCell>
                            {process.env.REACT_APP_PRODUCT_SERIAL ===
                              "true" && (
                              <TableCell align="right">
                                {translate("resources.root.serial")}
                              </TableCell>
                            )}
                            <TableCell align="right">
                              {translate("resources.root.quantity")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.gifts")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.basePrice")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.price")}
                            </TableCell>
                            {process.env.REACT_APP_ORDER_DISCOUNT ===
                              "true" && (
                              <TableCell align="right">
                                {translate("resources.root.discount")}
                              </TableCell>
                            )}
                            <TableCell align="right">
                              {translate("resources.root.totalPrice")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.actions")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.scrollable}>
                          {orders &&
                            orders.map((order) => (
                              <TableRow key={order.barcode}>
                                <TableCell component="th" scope="row">
                                  {order.barcode}
                                </TableCell>
                                <TableCell align="right">
                                  {order.name}
                                </TableCell>
                                {process.env.REACT_APP_PRODUCT_SERIAL ===
                                  "true" && (
                                  <TableCell align="right">
                                    {order.productSerial?.serialNumber}
                                  </TableCell>
                                )}
                                <TableCell
                                  onDoubleClick={() => {
                                    Swal.fire({
                                      title: "Change order Quantity",
                                      input: "number",
                                      inputValue: order.qte,
                                      showCancelButton: true,
                                      focusConfirm: true,
                                      confirmButtonColor: "#117baf",
                                      cancelButtonColor: "#B71316",
                                      inputValidator: (value) => {
                                        if (!value) {
                                          return "You need to write something!";
                                        }
                                      },
                                      confirmButtonText: translate(
                                        "resources.root.submit"
                                      ),

                                      preConfirm: (value) => {
                                        changeCol(
                                          order.productId,
                                          parseFloat(value),
                                          "qte"
                                        );
                                      },
                                    });
                                  }}
                                  align="right"
                                >
                                  {order.qte}
                                </TableCell>
                                <TableCell align="right">
                                  {order.giftQte}
                                </TableCell>
                                <TableCell align="right">
                                  {roundToTwo(
                                    order.scBasePurchasePrice
                                  )?.toLocaleString()}
                                </TableCell>
                                <TableCell
                                  onDoubleClick={() => {
                                    Swal.fire({
                                      title: "Change order Purchase Price",
                                      input: "number",
                                      inputValue: order.scPurchasePrice,
                                      showCancelButton: true,
                                      focusConfirm: true,
                                      confirmButtonColor: "#117baf",
                                      cancelButtonColor: "#B71316",
                                      inputValidator: (value) => {
                                        if (!value) {
                                          return "You need to write something!";
                                        }
                                      },
                                      confirmButtonText: translate(
                                        "resources.root.submit"
                                      ),

                                      preConfirm: (value) => {
                                        changeCol(
                                          order.productId,
                                          parseFloat(value),
                                          "scPurchasePrice"
                                        );
                                      },
                                    });
                                  }}
                                  align="right"
                                >
                                  {roundToTwo(
                                    order.scPurchasePrice
                                  )?.toLocaleString()}
                                </TableCell>
                                {process.env.REACT_APP_ORDER_DISCOUNT ===
                                  "true" && (
                                  <TableCell align="right">
                                    {roundToTwo(
                                      order.scDiscount
                                    )?.toLocaleString()}
                                  </TableCell>
                                )}
                                <TableCell align="right">
                                  {roundToTwo(
                                    order.scTotalPrice
                                  )?.toLocaleString()}
                                </TableCell>
                                <TableCell align="right">
                                  <DeleteIcon
                                    onClick={() => {
                                      swal({
                                        title: translate(
                                          "resources.root.makeSure"
                                        ),
                                        text: translate(
                                          "resources.root.confirmPerminantDelete"
                                        ),
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          setOrders((prev) => {
                                            return prev.filter(
                                              (el) =>
                                                el.productId != order.productId
                                            );
                                          });
                                        }
                                      });
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>{" "}
                    <Chip
                      label={`${translate(
                        "resources.root.basePrice"
                      )} - ${orders
                        .map(
                          (el) =>
                            parseFloat(el.scBasePurchasePrice) *
                              parseFloat(el.qte) -
                            parseFloat(el.scDiscount)
                        )
                        .reduce((a, b) => roundToTwo(a + b), 0)
                        .toLocaleString()} ${translate("resources.root.iqd")}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={`${translate(
                        "resources.root.totalPrice"
                      )} - ${orders
                        .map(
                          (el) =>
                            parseFloat(el.scPurchasePrice) *
                              parseFloat(el.qte) -
                            parseFloat(el.scDiscount)
                        )
                        .reduce((a, b) => roundToTwo(a + b), 0)
                        ?.toLocaleString()} ${translate("resources.root.iqd")}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={`${translate("resources.root.finalPrice")} - ${(
                        orders
                          .map(
                            (el) =>
                              parseFloat(el.scPurchasePrice) *
                                parseFloat(el.qte) -
                              parseFloat(el.scDiscount)
                          )
                          .reduce((a, b) => roundToTwo(a + b), 0) -
                        discount +
                        (parseFloat(tax) +
                          parseFloat(other) +
                          parseFloat(transport))
                      )?.toLocaleString()} ${translate("resources.root.iqd")}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={`${translate(
                        "resources.root.totalQuantity"
                      )} - ${orders
                        .map((el) => parseFloat(el.qte))
                        .reduce((a, b) => a + b, 0)
                        ?.toLocaleString()}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={`${translate(
                        "resources.root.totalGifts"
                      )} - ${orders
                        .map((el) => parseFloat(el.giftQte))
                        .reduce((a, b) => a + b, 0)
                        ?.toLocaleString()}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={`${translate(
                        "resources.root.finalQuantity"
                      )} - ${orders
                        .map(
                          (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                        )
                        .reduce((a, b) => a + b, 0)
                        ?.toLocaleString()}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={`${translate("resources.root.noOfOrders")} - ${
                        orders.length
                      }`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Snackbar
                      open={open}
                      autoHideDuration={4000}
                      onClose={handleClose}
                    >
                      <Alert onClose={handleClose} severity="error">
                        {errorMessage}
                      </Alert>
                    </Snackbar>
                  </div>
                  <div className="flex justify-content-between align-items-center gap-3 my-3">
                    <FormControlLabel
                      className={classes.inPercentage}
                      control={
                        <Switch
                          checked={
                            discountInPercent || orderDiscountPercentage > 0
                          }
                          onChange={(e) =>
                            setDiscountInPercent(e.target.checked)
                          }
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={translate("resources.root.inPercentage")}
                    />
                    <MaterialTextField
                      style={{ flexBasis: "50%" }}
                      type="number"
                      label={translate("resources.root.discountInPercentage")}
                      disabled={!discountInPercent || orderDiscount > 0}
                      value={
                        discountInPercent
                          ? roundToTwo(percentageDiscount)
                          : roundToTwo(
                              parseFloat(
                                roundToTwo(
                                  (discount * 100) /
                                    orders
                                      .map(
                                        (el) =>
                                          parseFloat(el.scPurchasePrice) *
                                          parseFloat(el.qte)
                                      )
                                      .reduce((a, b) => roundToTwo(a + b), 0)
                                )
                              )
                            )
                      }
                      variant="filled"
                      onChange={(e) => {
                        e.target.value >= 0 &&
                          e.target.value <= 100 &&
                          setPercentageDiscount(e.target.value);
                        setDiscount(
                          roundToTwo(
                            (orders
                              .map(
                                (el) =>
                                  parseFloat(el.scPurchasePrice) *
                                  parseFloat(el.qte)
                              )
                              .reduce((a, b) => a + b, 0) /
                              100) *
                              e.target.value
                          )
                        );
                      }}
                      className={classes.discount}
                    />
                    <MaterialTextField
                      style={{ flexBasis: "50%" }}
                      type="number"
                      label={translate("resources.root.discount")}
                      disabled={discountInPercent}
                      value={
                        !discountInPercent
                          ? roundToTwo(discount)
                          : roundToTwo(
                              (orders
                                .map(
                                  (el) =>
                                    parseFloat(el.scPurchasePrice) *
                                    parseFloat(el.qte)
                                )
                                .reduce((a, b) => a + b, 0) /
                                100) *
                                percentageDiscount
                            )
                      }
                      variant="filled"
                      onChange={(e) => {
                        e.target.value >= 0 &&
                          e.target.value <=
                            orders
                              .map(
                                (el) =>
                                  parseFloat(el.scPurchasePrice) *
                                  parseFloat(el.qte)
                              )
                              .reduce((a, b) => a + b, 0) &&
                          setDiscount(e.target.value);
                        setPercentageDiscount(
                          parseFloat(
                            (e.target.value * 100) /
                              orders
                                .map(
                                  (el) =>
                                    parseFloat(el.scPurchasePrice) *
                                    parseFloat(el.qte)
                                )
                                .reduce((a, b) => roundToTwo(a + b), 0)
                          )
                        );
                      }}
                      className={`${classes.discount} mx-4`}
                    />
                  </div>
                  {(process.env.REACT_APP_APP_NAME === "Bahez" ||
                    process.env.REACT_APP_APP_NAME === "IndustryTemp") && (
                    <div className="flex justify-content-between align-items-center gap-3 my-3">
                      <FormControl
                        style={{ flexBasis: "20%" }}
                        variant="filled"
                      >
                        <InputLabel htmlFor="TaxInput">
                          {translate("resources.root.tax")}
                        </InputLabel>
                        <FilledInput
                          id="TaxInput"
                          type="number"
                          disabled={orders.length === 0 || !isActiveDisBut}
                          value={tax}
                          onChange={(e) => {
                            e.target.value >= 0 &&
                              e.target.value <=
                                orders
                                  .map(
                                    (el) =>
                                      parseFloat(el.scPurchasePrice) *
                                      parseFloat(el.qte)
                                  )
                                  .reduce((a, b) => a + b, 0) &&
                              setTax(e.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl
                        style={{ flexBasis: "20%" }}
                        variant="filled"
                      >
                        <InputLabel htmlFor="OtherInput">
                          {translate("resources.root.other")}
                        </InputLabel>
                        <FilledInput
                          id="OtherInput"
                          type="number"
                          disabled={orders.length === 0 || !isActiveDisBut}
                          value={other}
                          onChange={(e) => {
                            e.target.value >= 0 &&
                              e.target.value <=
                                orders
                                  .map(
                                    (el) =>
                                      parseFloat(el.scPurchasePrice) *
                                      parseFloat(el.qte)
                                  )
                                  .reduce((a, b) => a + b, 0) &&
                              setOther(e.target.value);
                          }}
                        />
                      </FormControl>
                      <FormControl
                        style={{ flexBasis: "20%" }}
                        variant="filled"
                      >
                        <InputLabel htmlFor="TransportInput">
                          {translate("resources.root.transport")}
                        </InputLabel>
                        <FilledInput
                          id="TransportInput"
                          type="number"
                          disabled={orders.length === 0 || !isActiveDisBut}
                          value={transport}
                          onChange={(e) => {
                            e.target.value >= 0 &&
                              e.target.value <=
                                orders
                                  .map(
                                    (el) =>
                                      parseFloat(el.scPurchasePrice) *
                                      parseFloat(el.qte)
                                  )
                                  .reduce((a, b) => a + b, 0) &&
                              setTransport(e.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControlLabel
                        className="m-0"
                        control={
                          <Switch
                            checked={isActiveDisBut}
                            onChange={(e) => {
                              setIsActiveDisBut(!isActiveDisBut);
                            }}
                            color="primary"
                          />
                        }
                        label={translate("resources.root.isActive")}
                      />
                    </div>
                  )}

                  <div className="d-flex justify-content-center align-items-center gap-4">
                    <button
                      type="button"
                      onClick={() => {
                        distributeTaxOtherTransport(orders);
                        setPercentageDiscount(0);
                        setDiscount(0);
                        setIsActiveDisBut(false);
                      }}
                      disabled={!isActiveDisBut}
                      className="btn btn-block btn-primary flex-1"
                    >
                      {translate("resources.root.distribute")}
                    </button>
                    <Tooltip
                      title={translate(
                        "resources.root.restoreDiscountToOriginal"
                      )}
                    >
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-block my-2"
                        disabled={delay}
                        onClick={() => {
                          let oldTotal = orders
                            .map(
                              (el) =>
                                parseFloat(el.scPurchasePrice) *
                                parseFloat(el.qte)
                            )
                            .reduce((a, b) => a + b, 0);
                          orders.map((el) => {
                            el.scPurchasePrice = parseFloat(
                              el.scBasePurchasePrice
                            );
                            el.scTotalPrice =
                              parseFloat(el.qte) *
                              parseFloat(el.scPurchasePrice);
                          });
                          let newTotal = orders
                            .map(
                              (el) =>
                                parseFloat(el.scPurchasePrice) *
                                parseFloat(el.qte)
                            )
                            .reduce((a, b) => a + b, 0);
                          if (oldTotal === newTotal) {
                            return;
                          }
                          // TODO: update the state and Restore Gift Qte as well
                          setPercentageDiscount(
                            100 - (oldTotal / newTotal) * 100
                          );
                        }}
                      >
                        {translate("resources.root.restore")}
                      </button>
                    </Tooltip>{" "}
                  </div>
                  <div className={classes.orderCols}>
                    <MaterialTextField
                      label={translate("resources.root.note")}
                      value={productNote}
                      onChange={(e) => setProductNote(e.target.value)}
                      className={classes.noteInput}
                    />
                    <MaterialTextField
                      label={translate("resources.root.driver")}
                      value={driver}
                      onChange={(e) => setDriver(e.target.value)}
                      className={classes.orderTextInput}
                    />
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          <Button
            size="medium"
            color="secondary"
            disabled={delay}
            variant="contained"
            onClick={() => {
              setSubmitAttempted(true);
              if (!selectedSupplier) {
                setErrorMessage(translate("resources.root.selectSupplier"));
                // setOpen(true);
                return;
              }
              swal({
                title: translate("resources.root.makeSure"),
                text: translate("resources.root.confirmCreateNewInvoice"),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                setDelay(true);
                if (willDelete) {
                  setIsLoading(true);
                  let purchaseOrders, returnOrders;
                  if (invoice === "return") {
                    returnOrders = orders;
                  } else {
                    purchaseOrders = orders;
                  }
                  HTTP.post(
                    `${url}/${
                      invoice === "return"
                        ? "PurchaseReturnInvoices"
                        : "PurchaseInvoices"
                    }`,
                    {
                      theirNumber: invoiceNumber,
                      voucherNumber: invoiceNumber,
                      supplierId: selectedSupplier.id,
                      date: selectedDate,
                      voucherDate: selectedDate,
                      scTotalPrice: orders
                        .map(
                          (el) =>
                            parseFloat(el.scPurchasePrice) * parseFloat(el.qte)
                        )
                        .reduce((a, b) => a + b, 0),
                      scFinalPrice:
                        orders
                          .map(
                            (el) =>
                              parseFloat(el.scPurchasePrice) *
                              parseFloat(el.qte)
                          )
                          .reduce((a, b) => a + b, 0) - discount,
                      isCash: cashPayment,
                      payByMain: false,
                      totalQte: orders
                        .map((el) => el.qte)
                        .reduce((a, b) => a + b, 0),
                      totalGiftQte: orders
                        .map((el) => el.giftQte)
                        .reduce((a, b) => a + b, 0),
                      scInvoiceDiscount: discount,
                      scOrdersDiscount: 0,
                      scFinalDiscount: 0,
                      note: productNote,
                      driver,
                      idemPotentToken: idemPotent,
                      purchaseOrders: purchaseOrders,
                      purchaseReturnOrders: returnOrders,
                      scTax: parseFloat(tax),
                      scOther: parseFloat(other),
                      scTransport: parseFloat(transport),
                    }
                  )
                    .then((res) => {
                      localStorage.removeItem("scTax");
                      localStorage.removeItem("scOther");
                      localStorage.removeItem("scTransport");
                      if (invoice === "return") {
                        localStorage.removeItem("scPurchaseReturnOrders");
                      } else {
                        localStorage.removeItem("scPurchaseOrders");
                      }
                      redirect(
                        invoice !== "return"
                          ? "/PurchaseInvoices"
                          : "/PurchaseReturnInvoices"
                      );
                    })
                    .catch((err) => {
                      setIsLoading(false);
                      notify(err.response.data.message, {
                        type: "error",
                        undoable: true,
                      });
                    });
                } else {
                  swal(translate("resources.root.createCancel"));
                }
              });
            }}
          >
            {translate("resources.root.create")}
          </Button>
          <Button
            onClick={() => {
              swal({
                title: translate("resources.root.makeSure"),
                text: translate("resources.root.confirmCreateNewInvoice"),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  setOrders([]);
                } else {
                  swal(translate("resources.root.createCancel"));
                }
              });
            }}
          >
            {translate("resources.root.clearOrders")}
          </Button>
        </CardActions>
      </Card>
      {isLoading && <LoadingScreen />}
    </StylesProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 72,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  dateTimeInput: {
    width: "100% !important",
    margin: "0 10px -35px",
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount2: {
    width: "40% !important",
    margin: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: 5,
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
    margin: "0 10px",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "350px !important",
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "140%",
      marginLeft: theme.direction === "rtl" ? 0 : -230,
      marginRight: theme.direction === "rtl" ? -230 : -230,
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "80%",
      marginLeft: 265,
      paddingTop: 60,
      //   marginRight: 230,
    },
  },
}));
