import * as React from "react";
import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import _ from "lodash";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(50);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(50);
  const [data, setData] = React.useState([]);
  const [customers, setCustomers] = React.useState();

  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalItems, setTotalItems] = React.useState(0);
  const [scTotalPrice, setScTotalPrice] = React.useState(0);
  const [scDiscount, setScDiscount] = React.useState(0);
  const [scTotalCost, setScTotalCost] = React.useState(0);
  const [labTotalPrice, setlabTotalPrice] = React.useState(0);
  const [xRayTotalPrice, setxRayTotalPrice] = React.useState(0);
  const [medItemTotalPrice, setmedItemTotalPrice] = React.useState(0);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    }
    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    }

    let customer = ``;

    if (customers) {
      customer = `&subLevelId=${customers}`;
    } else {
      customer = ``;
    }

    await HTTP.get(
      `${url}/CustomerVisits?_start=${start}&_end=${end}${sendDates}${customer}&forReport=${true}`
    )
      .then((response) => {
        setTotalItems(response.headers["x-total-count"]);
        setScTotalPrice(response.headers["sctotalprice"]);
        setScTotalCost(response.headers["sctotalcost"]);
        setlabTotalPrice(response.headers["sctotallabtestsellprice"]);
        setmedItemTotalPrice(response.headers["sctotalmeditemsellprice"]);
        setxRayTotalPrice(response.headers["sctotalxraysellprice"]);
        setData(response.data);
        setScDiscount(_.sumBy(response.data, "scDiscountPrice"));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(() => {
    setIsLoading(true);
    getData();
  }, [(start, end)]);
  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Customers?_end=100&search=${inputValue}`
      );
      let newArray = [];
      newArray.push({
        value: 0,
        label: "none",
        productCategory: "none",
      });
      response.data.map((customer) => {
        newArray.push({
          value: customer.id,
          label: customer.fullName,
          customer: customer,
        });
      });
      callback(newArray);
    }
  };

  return (
    <div className="my-5">
      <PrintProvider>
        <Print single name={`customersStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("ra.navigation.PatientVisitsReport")}
            </h1>
          </div>
          <NoPrint force>
            {isLoading && <LoadingScreen />}
            <Row className="d-flex align-items-center justify-content-around my-3">
              <Col className="row col-12 col-md-6 ">
                <Col className="col-12 col-md-6 mb-3">
                  <Form.Group controlId="startDate">
                    <Col className="d-flex align-items-center m-0 p-0">
                      <Form.Label className="m-0 mr-2 text-nowrap">
                        {translate("resources.root.startDate")} :
                      </Form.Label>
                      <Form.Control
                        className=""
                        label="startDate"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col className="col-12 col-md-6 mb-3">
                  <Form.Group controlId="endDate">
                    <Col className="d-flex align-items-center m-0 p-0">
                      <Form.Label className="m-0 mr-2  text-nowrap">
                        {translate("resources.root.endDate")} :
                      </Form.Label>
                      <Form.Control
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Col>
              <Col className="col-6 d-flex align-items-center justify-content-start m-0 px-3">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.customer")} :
                </p>
                <AsyncSelect
                  defaultOptions
                  className="flex-grow-1"
                  loadOptions={loadOptions}
                  onChange={(e) => {
                    setCustomers(e ? e.value : 0);
                  }}
                />
              </Col>

              <Col className="col-4 pt-3">
                <NoPrint>
                  <Button
                    variant={"outline-primary btn-block"}
                    onClick={() => {
                      let sidebar =
                        document.getElementsByClassName("MuiDrawer-root");
                      let appMenu =
                        document.getElementsByClassName("MuiPaper-root");
                      let appBar = document.getElementsByClassName("theRoot");
                      let table = document.getElementsByTagName("table");
                      sidebar[0].style.display = "none";
                      appMenu[0].style.display = "none";
                      appBar[0].style.marginTop = "0px";
                      table[0].style.fontSize = "12px";
                      window.print();
                      sidebar[0].style.display = "block";
                      appMenu[0].style.display = "block";
                      appBar[0].style.marginTop = "40px";
                      table[0].style.fontSize = "15px";
                    }}
                  >
                    <FaPrint /> {translate("resources.root.print")}
                  </Button>
                </NoPrint>
              </Col>
              <Col className="col-4 pt-3">
                <Button
                  id="export"
                  className="btn btn-primary btn-block"
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-4 pt-3">
                <Button
                  variant={"outline-primary btn-block"}
                  onClick={() => {
                    getData();
                  }}
                >
                  {translate("resources.root.search")}
                </Button>
              </Col>
              <Col className="col-12 mt-4">
                <Table bordered hover className="">
                  <thead>
                    <tr>
                      <th>{translate("resources.root.totalVisitCount")}</th>
                      <th>{translate("resources.root.labTests")}</th>
                      <th>{translate("resources.root.XRays")}</th>
                      <th>{translate("resources.root.medItems")}</th>
                      <th>{translate("resources.root.discount")}</th>
                      <th>{translate("resources.root.costPrice")}</th>
                      <th>{translate("resources.root.totalPrice")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 && (
                      <tr>
                        <td>{totalItems?.toLocaleString()}</td>
                        <td>{labTotalPrice?.toLocaleString()} IQD</td>
                        <td>{xRayTotalPrice?.toLocaleString()} IQD</td>
                        <td>{medItemTotalPrice?.toLocaleString()} IQD</td>
                        <td>{scDiscount?.toLocaleString()} IQD</td>
                        <td>{scTotalCost?.toLocaleString()} IQD</td>
                        <td>{scTotalPrice?.toLocaleString()} IQD</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`mt-2`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.doctorName")}</th>
                    <th>{translate("resources.root.department")}</th>
                    <th>{translate("resources.root.visitPrice")}</th>
                    <th>{translate("resources.root.totalPrice")}</th>
                    <th>{translate("resources.root.discount")}</th>
                    <th>{translate("resources.root.freeVisit")}</th>
                    <th>{translate("resources.root.miner")}</th>
                    <th>{translate("resources.root.date")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr>
                          <td>
                            <a
                              href={`#/CustomerVisits/${one.id}/show`}
                              target={"_blank"}
                            >
                              {one.id}
                            </a>
                          </td>
                          <td>{one.customerName}</td>

                          <td>{one.doctorName}</td>
                          <td>{one.doctorDepartmentName}</td>
                          <td>
                            {one.scSellPrice?.toLocaleString()} IQD
                            {/* {process.env.REACT_APP_SINGLE_CURRENCY === "true"
                              ? process.env.REACT_APP_SHOW_MC === "true"
                                ? `$${one.mcSellPrice?.toLocaleString()}`
                                : `${one.scSellPrice?.toLocaleString()} IQD`
                              : localStorage.getItem("sellMainCurrency") ===
                                "true"
                              ? `$${one.mcSellPrice?.toLocaleString()}`
                              : `${one.scSellPrice?.toLocaleString()} IQD`} */}
                          </td>
                          <td>
                            {one.calculatedTotalPrice?.toLocaleString()} IQD
                            {/* {process.env.REACT_APP_SINGLE_CURRENCY === "true"
                              ? process.env.REACT_APP_SHOW_MC === "true"
                                ? `$${one.calculatedTotalPrice?.toLocaleString()}`
                                : `${one.calculatedTotalPrice?.toLocaleString()} IQD`
                              : localStorage.getItem("sellMainCurrency") ===
                                "true"
                              ? `$${one.calculatedTotalPrice?.toLocaleString()}`
                              : `${one.calculatedTotalPrice?.toLocaleString()} IQD`} */}
                          </td>
                          <td>{one.scDiscountPrice?.toLocaleString()} IQD</td>
                          <td>
                            {translate(
                              one.isFreeVisit
                                ? "resources.root.yes"
                                : "resources.root.No"
                            )}
                          </td>
                          <td>
                            {translate(
                              one.feature1
                                ? "resources.root.yes"
                                : "resources.root.No"
                            )}
                          </td>
                          <td>{moment(one.date).format("YYYY-MM-DD")}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>Per Page</InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
