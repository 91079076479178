import * as React from "react";
import { url } from "../../request";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useNotify, useRedirect, useTranslate } from "react-admin";

import moment from "moment";
import Select from "react-select";
import { PrintProvider, Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
export default () => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  const [data, setData] = React.useState([]);

  const [representatives, setRepresentatives] = React.useState([]);
  const [representative, setRepresentative] = React.useState({});
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [totalPrices, setTotalPrices] = React.useState({
    notPaid: [],
    paid: [],

    totalPrice: {
      mc: 0,
      sc: 0,
    },
    totalDiscount: {
      mc: 0,
      sc: 0,
    },
    totalNotPaidPrice: {
      mc: 0,
      sc: 0,
    },
    totalPaidPrice: {
      mc: 0,
      sc: 0,
    },
    repExpesne: 0,
    returnPrice: 0,
    repDiscount: 0,
    note: "",
  });

  const [isLoading, setIsLoading] = React.useState(false);

  async function getData() {
    let startD = ``;
    let endD = ``;
    let repId = "";

    if (representative?.value !== 0) repId = `${representative?.value}`;

    if (startDate && startDate.length > 0) startD = `startDate=${startDate}`;
    if (endDate && endDate.length > 0) endD = `&endDate=${endDate}`;

    await HTTP.get(
      `${url}/RepresentativeCustomerSellInvoices/RepresentativeReport/${repId}?${startD}&_start=0&_end=1000`
    )
      .then((response) => {
        setTotalPrices({
          ...totalPrices,
          totalPrice: {
            mc: parseFloat(response.headers.mctotalprice),
            sc: parseFloat(response.headers.sctotalprice),
          },
          totalDiscount: {
            mc: parseFloat(response.headers["mcdiscount"]),
            sc: parseFloat(response.headers["scdiscount"]),
          },
        });
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
      });
    // Not paid request
    await HTTP.get(
      `${url}/RepresentativeCustomerSellInvoices?representativeId=${repId}&${startD}${endD}&isCash=${false}&_start=0&_end=1000`
    )
      .then((response) => {
        setTotalPrices((prevState) => ({
          ...prevState,
          notPaid: response.data,
          totalNotPaidPrice: {
            mc: parseFloat(response.headers["mctotalprice"]),
            sc: parseFloat(response.headers["sctotalprice"]),
          },
        }));
      })
      .catch((error) => {
        if (error.response.status === 401) redirect(`/Login`);
      });
    // Payment Request
    await HTTP.get(
      `${url}/RepresentativeCustomerPayments?representativeId=${repId}&${startD}${endD}&onlyAccepted=${true}&_start=0&_end=1000`
    )
      .then((response) => {
        setTotalPrices((prevState) => ({
          ...prevState,
          paid: response.data,
          totalPaidPrice: {
            mc: parseFloat(response.headers["mctotalprice"]),
            sc: parseFloat(response.headers["sctotalprice"]),
          },
        }));
      })
      .catch((error) => {
        if (error.response.status === 401) redirect(`/Login`);
      });
    setIsLoading(false);
  }
  React.useEffect(function () {
    getRepresentative("");
  }, []);

  function getRepresentative(value) {
    HTTP.get(`${url}/Representatives?_end=10000&search=${value}`)
      .then((response) => {
        let newArray = [];

        response.data.map((representative) => {
          newArray.push({
            value: representative.id,
            label: `${representative.firstName} ${representative.middleName} ${representative.lastName}`,
            representative: representative,
          });
        });
        setRepresentatives(newArray);
      })
      .catch((err) =>
        notify(err.response.data.message, { type: "error", undoable: true })
      );
  }

  return (
    <div className="m-3">
      <PrintProvider>
        <Print single>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate(
                "resources.root.representativeCustomerSellInvoiceReport"
              )}
            </h1>
          </div>{" "}
        </Print>
        <NoPrint force>
          <Row className="px-4 px-lg-0">
            {isLoading && <LoadingScreen />}

            <Col className="col-12 col-lg-4 d-flex justify-content-start align-items-center mt-3">
              <p className="p-0 m-0">
                {translate("resources.root.representative")}: &nbsp;
              </p>
              <Select
                name="colors"
                isClearable
                isSearchable
                className="basic-multi-select w-75 "
                classNamePrefix="select"
                options={representatives}
                onChange={(e) => {
                  setRepresentative(e ? e : 0);
                }}
              />
            </Col>

            <Col className="row col-12 col-lg-8 align-items-center mt-3">
              <Col className="col-12 col-md-6">
                <Form.Group controlId="startDate">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2 text-nowrap">
                      {translate("resources.root.startDate")} :
                    </Form.Label>
                    <Form.Control
                      className=""
                      label="startDate"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col className="col-12 col-md-6">
                <Form.Group controlId="endDate">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2  text-nowrap">
                      {translate("resources.root.endDate")} :
                    </Form.Label>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Col>
            {process.env.APP_SINGLE_CURRENCY === "true" ? (
              process.env.APP_SHOW_MC ? (
                <Col className="col-12 col-md-6 col-lg-6  d-flex justify-content-center align-items-center mt-3">
                  <h5>
                    {translate("resources.root.total")} :
                    {totalPrices?.totalPrice?.mc?.toLocaleString()}$
                  </h5>
                </Col>
              ) : (
                <Col className="col-12 col-md-6 col-lg-6  d-flex justify-content-center align-items-center mt-3">
                  <h5>
                    {translate("resources.root.total")} :
                    {totalPrices?.totalPrice?.sc?.toLocaleString()}IQD
                  </h5>
                </Col>
              )
            ) : (
              <Col className="col-12 col-md-6 col-lg-4  d-flex justify-content-around align-items-center mt-3">
                <h5>
                  {translate("resources.root.total")} :
                  {totalPrices?.totalPrice?.mc?.toLocaleString()}$
                </h5>
                <h5>
                  {translate("resources.root.total")} :
                  {totalPrices?.totalPrice?.sc?.toLocaleString()} IQD
                </h5>
              </Col>
            )}
            <Col className="row col-12 col-lg-4 align-items-center mt-3">
              <Form.Group
                controlId="startDate"
                className="d-flex justify-content-center align-items-center w-100"
              >
                <Form.Label className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.representativeExpense")} :
                </Form.Label>
                <Form.Control
                  type="number"
                  className="w-100"
                  value={totalPrices?.repExpesne}
                  variant="outlined"
                  onChange={(e) => {
                    e.target.value >= 0 &&
                      setTotalPrices({
                        ...totalPrices,
                        repExpesne: e.target.value,
                      });
                  }}
                />{" "}
              </Form.Group>
            </Col>
            <Col className="row col-12 col-lg-4 align-items-center mt-3">
              <Form.Group
                controlId="startDate"
                className="d-flex justify-content-center align-items-center w-100"
              >
                <Form.Label className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.representativeDiscount")} :
                </Form.Label>
                <Form.Control
                  type="number"
                  className="w-100"
                  value={totalPrices?.repDiscount}
                  variant="outlined"
                  onChange={(e) => {
                    e.target.value >= 0 &&
                      setTotalPrices({
                        ...totalPrices,
                        repDiscount: e.target.value,
                      });
                  }}
                />{" "}
              </Form.Group>
            </Col>
            <Col className="row col-12 col-lg-4 align-items-center mt-3">
              <Form.Group
                controlId="startDate"
                className="d-flex justify-content-center align-items-center w-100"
              >
                <Form.Label className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.returnPrice")} :
                </Form.Label>
                <Form.Control
                  type="number"
                  className="w-100"
                  value={totalPrices?.returnPrice}
                  variant="outlined"
                  onChange={(e) => {
                    e.target.value >= 0 &&
                      setTotalPrices({
                        ...totalPrices,
                        returnPrice: e.target.value,
                      });
                  }}
                />{" "}
              </Form.Group>
            </Col>
            <Col className="row col-12 col-lg-8 align-items-center mt-3">
              <Form.Group
                controlId="startDate"
                className="d-flex justify-content-center align-items-center w-100"
              >
                <Form.Label className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.note")} :
                </Form.Label>
                <Form.Control
                  className="w-100"
                  value={totalPrices?.note}
                  variant="outlined"
                  onChange={(e) => {
                    setTotalPrices({
                      ...totalPrices,
                      note: e.target.value,
                    });
                  }}
                />{" "}
              </Form.Group>
            </Col>

            <Col className="col-12 col-md-6 col-lg-4 mt-3">
              <Button
                className="btn-block"
                id="export"
                disabled={!Boolean(representative)}
                onClick={() => {
                  toCsv(
                    document.getElementById(
                      "representataiveCustomerSellIncoicesTableReport"
                    ),
                    "Repsentative customer sell invoices.csv"
                  );
                }}
              >
                {translate("resources.root.exportCsv")}
              </Button>
            </Col>
            <Col className="col-12 col-md-6 col-lg-4 mt-3">
              <Button
                className="btn-block"
                variant={"outline-primary"}
                disabled={!Boolean(representative)}
                onClick={(e) => {
                  let sidebar =
                    document.getElementsByClassName("MuiDrawer-root");
                  let appMenu =
                    document.getElementsByClassName("MuiPaper-root");
                  let appBar = document.getElementsByClassName("theRoot");
                  let table = document.getElementsByTagName("table");
                  sidebar[0].style.display = "none";
                  appMenu[0].style.display = "none";
                  appBar[0].style.marginTop = "0px";
                  table[0].style.fontSize = "12px";
                  window.print();
                  sidebar[0].style.display = "block";
                  appMenu[0].style.display = "block";
                  appBar[0].style.marginTop = "40px";
                  table[0].style.fontSize = "15px";
                }}
              >
                <FaPrint /> {translate("resources.root.print")}
              </Button>
            </Col>
            <Col className="col-12 col-md-6 col-lg-4 mt-3">
              <Button
                className="btn-block"
                id="export"
                disabled={!Boolean(representative)}
                onClick={() => {
                  setIsLoading(true);
                  getData();
                }}
              >
                {translate(
                  `resources.root.${isLoading ? "isLoading" : "search"}`
                )}
              </Button>
            </Col>
          </Row>
        </NoPrint>
        <Print single name={`expireReport`}>
          <Row className={`mt-3`}>
            <Col>
              {data.length > 0 && !isLoading == true && (
                <table
                  id={`representataiveCustomerSellIncoicesTableReport`}
                  className="text-center representataiveCustomerSellIncoicesTableReport"
                  dir="rtl"
                >
                  <tr>
                    <th colSpan="4">{representative.label}</th>
                    <th>{startDate}</th>
                  </tr>
                  <tr>
                    <th>بابەت</th>
                    <th>بارکراو</th>
                    <th>نرخی تاک</th>
                    <th>گەڕاو</th>
                    <th>کۆی گشتی</th>
                  </tr>
                  <tbody>
                    {data?.map((item) => {
                      return (
                        <tr key={item?.id}>
                          <td>{item?.name}</td>
                          <td>{item?.qty}</td>
                          {item?.byMain ? (
                            <td dir="ltr">
                              $
                              {(
                                item?.mcTotalPrice / item?.qty
                              )?.toLocaleString()}
                            </td>
                          ) : (
                            <td dir="ltr">
                              {(
                                item?.scTotalPrice / item?.qty
                              )?.toLocaleString()}{" "}
                              IQD
                            </td>
                          )}

                          <td>{item?.returnQty}</td>
                          {item?.byMain ? (
                            <td className="table-yellow" dir="ltr">
                              ${item?.mcTotalPrice?.toLocaleString()}
                            </td>
                          ) : (
                            <td className="table-yellow" dir="ltr">
                              {item?.scTotalPrice?.toLocaleString()} IQD
                            </td>
                          )}
                        </tr>
                      );
                    })}
                    <tr>
                      <td className="table-blue-sky">بڕی فرۆشراو</td>
                      <td colSpan={3}></td>

                      <td dir="ltr" className="table-blue-sky">
                        {totalPrices?.totalPrice?.sc?.toLocaleString()} IQD
                      </td>
                    </tr>
                    {/* Not paid */}
                    {totalPrices?.notPaid?.length > 0 && (
                      <tr className="table-gray">
                        <td className="text-center ">غیر واصل</td>
                        <td className=" p-0 m-0" colSpan={3}>
                          {totalPrices?.notPaid?.map((item) => {
                            return (
                              <div className="d-flex justify-content-between align-items-center">
                                <span className="mixedTd w-100 p-2 ">{`${item?.representativeCustomer?.owner} - ${item?.representativeCustomer?.placeName}`}</span>
                                <span className="mixedTd w-100 p-2" dir="ltr">
                                  {item?.scFinalPrice?.toLocaleString()}{" "}
                                </span>
                              </div>
                            );
                          })}
                        </td>
                        <td className="text-center" dir="ltr">
                          {totalPrices?.totalNotPaidPrice.sc?.toLocaleString()}
                          &nbsp;IQD
                        </td>
                      </tr>
                    )}

                    {totalPrices?.totalDiscount.sc && (
                      <tr>
                        <td className="table-blue-sky">خەسمی دووکان</td>
                        <td colSpan={3}></td>

                        <td dir="ltr" className="table-blue-sky">
                          {totalPrices?.totalDiscount.sc?.toLocaleString()}
                          &nbsp;IQD
                        </td>
                      </tr>
                    )}
                    {totalPrices?.repExpesne > 0 && (
                      <tr>
                        <td className="table-blue-sky">مەسرەفی مەندووب</td>
                        <td colSpan={3}></td>

                        <td dir="ltr" className="table-blue-sky">
                          {Number(totalPrices?.repExpesne)?.toLocaleString()}
                          &nbsp;IQD
                        </td>
                      </tr>
                    )}
                    {totalPrices?.returnPrice > 0 && (
                      <tr>
                        <td className="table-blue-sky">گەڕاوەی مەندووب</td>
                        <td colSpan={3}></td>

                        <td dir="ltr" className="table-blue-sky">
                          {Number(totalPrices?.returnPrice)?.toLocaleString()}
                          &nbsp;IQD
                        </td>
                      </tr>
                    )}
                    {totalPrices?.note && (
                      <tr>
                        <td className="table-gray">تێبینی</td>

                        <td dir="ltr" className="table-gray" colSpan={4}>
                          {totalPrices?.note}
                        </td>
                      </tr>
                    )}
                    {totalPrices?.repDiscount > 0 && (
                      <tr>
                        <td className="table-blue-sky">خەسمی مەندووب</td>
                        <td colSpan={3}></td>

                        <td dir="ltr" className="table-blue-sky">
                          {Number(totalPrices?.repDiscount)?.toLocaleString()}
                          &nbsp;IQD
                        </td>
                      </tr>
                    )}

                    {/*  paid */}
                    {totalPrices?.paid.length > 0 && (
                      <tr className="table-red">
                        <td className="text-center ">پارەی وەرگیراو</td>
                        <td className=" p-0 m-0" colSpan={3}>
                          {totalPrices?.paid?.map((item) => {
                            return (
                              <div className="d-flex justify-content-between align-items-center">
                                <span className="mixedTd w-100 p-2 ">{`${item?.representativeCustomer?.owner} - ${item?.representativeCustomer?.placeName}`}</span>
                                <span className="mixedTd w-100 p-2" dir="ltr">
                                  {item?.scPrice?.toLocaleString()}&nbsp;IQD
                                </span>
                              </div>
                            );
                          })}
                        </td>
                        <td className="text-center" dir="ltr">
                          {totalPrices?.totalPaidPrice.sc?.toLocaleString()}
                          &nbsp;IQD
                        </td>
                      </tr>
                    )}

                    <tr className="">
                      <td className="table-gray">پارەی کاش</td>
                      <td colSpan="3"></td>
                      <td className="table-gray" dir="ltr">
                        {(
                          totalPrices?.totalPaidPrice?.sc -
                          Number(totalPrices.repExpesne) -
                          Number(totalPrices.returnPrice)
                        )?.toLocaleString()}
                        &nbsp;IQD
                      </td>
                    </tr>

                    {/* Discount */}
                    {/* <tr>
                      <td className="table-blue-sky">خەسمی دووکان</td>
                      <td colSpan="3"></td>
                      <td className="table-blue-sky" dir="ltr">
                        {totalDiscount?.sc?.toLocaleString()} IQD
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              )}
              {/* <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClassName="page-item"
                    linkClassName="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint> */}
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
