import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  useTranslate,
  TabbedForm,
  FunctionField,
  FormTab,
  Create,
  ReferenceField,
  Show,
  TabbedShowLayout,
  Tab,
  NumberField,
  DateField,
  useResourceDefinition,
  ShowButton,
} from "react-admin";
import { url, handleRequestResponse } from "../../request";
import { ScPurchaseInvoiceNew } from "./ScPurchaseInvoiceNew";
import { McPurchaseInvoiceNew } from "./McPurchaseInvoiceNew";
import moment from "moment";
import PrintProvider, { NoPrint, Print } from "react-easy-print";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { ListActions } from "../templates/ListActions";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/SellReceipt/Page";
import { HTTP } from "../../axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

export const PurchaseReturnsList = () => {
  const [currency, setCurrency] = React.useState("default");
  const props = useResourceDefinition();
  const translate = useTranslate();
  return (
    <List
      debounce={1500}
      filters={<SearchFilter driver={true} date={true} />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate="returnpurchaseinvoice.create"
        />
      }
    >
      <Datagrid
        bulkActionButtons={
          <BulkAction
            {...props}
            hasPrintOrders={"PurchaseReturnInvoice"}
            hasDelete="returnpurchaseinvoice.delete"
          />
        }
      >
        <NumberField source="id" label={translate("resources.root.id")} />

        <ReferenceField
          label="resources.root.supplier"
          source="supplierId"
          reference="Suppliers"
          link={(record) => `/Reports/SupplierStatement/${record.supplier.id}`}
        >
          <TextField source="supplierName" />
        </ReferenceField>
        {localStorage.getItem("showInvoiceNumber") === "true" && (
          <TextField
            source="invoiceNumber"
            label="resources.root.invoiceNumber"
          />
        )}
        <FunctionField
          label="resources.root.date"
          sortBy={"date"}
          render={(record) =>
            `${moment(record.date).format("DD/MM/YYYY hh:mm:ss A")}`
          }
        />
        <FunctionField
          label="Price"
          sortBy={"mcFinalPrice"}
          render={(record) =>
            record.payByMain
              ? `$${record.mcFinalPrice?.toLocaleString()}`
              : `${record.scFinalPrice?.toLocaleString()} ${translate(
                  "resources.root.iqd"
                )}`
          }
        />
        <TextField
          source="totalQte"
          label={translate("resources.root.totalQte")}
        />
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}
        {localStorage.getItem("showDriver") === "true" && (
          <TextField source="driver" label="resources.root.driver" />
        )}
        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const PurchaseReturnsCreate = (props) => {
  const [suppliers, setSuppliers] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [modifiedPrice, setModifiedPrice] = React.useState(0);
  const [orderToEdit, setOrderToEdit] = React.useState(-1);

  React.useEffect(() => {
    HTTP.get(`${url}/Suppliers`).then((res) => {
      setSuppliers(res.data);
    });
    HTTP.get(`${url}/Products`).then((res) => {
      setProducts(res.data);
    });
  }, []);

  React.useEffect(() => {
    selectedProduct && (selectedProduct.mcSellPrice = modifiedPrice);
  }, [modifiedPrice]);

  React.useEffect(() => {
    setSelectedProduct(products.filter((el) => el.id === orderToEdit)[0]);
  }, [orderToEdit]);
  return (
    <Create {...props}>
      {process.env.REACT_APP_SINGLE_CURRENCY === "true" ? (
        process.env.REACT_APP_SHOW_MC === "true" ? (
          <TabbedForm>
            <FormTab label="resources.root.dollarPurchase">
              <McPurchaseInvoiceNew invoice="return" />
            </FormTab>
          </TabbedForm>
        ) : (
          <TabbedForm>
            <FormTab label="resources.root.dinarPurchase">
              <ScPurchaseInvoiceNew invoice="return" />
            </FormTab>
          </TabbedForm>
        )
      ) : (
        <TabbedForm>
          <FormTab label="resources.root.dollarPurchase">
            <McPurchaseInvoiceNew invoice="return" />
          </FormTab>
          <FormTab label="resources.root.dinarPurchase">
            <ScPurchaseInvoiceNew invoice="return" />
          </FormTab>
        </TabbedForm>
      )}
    </Create>
  );
};

export const PurchaseReturnsShow = () => {
  const { id } = useParams();
  const props = useResourceDefinition();
  const [orders, setOrders] = React.useState([]);
  const [invoice, setInvoice] = React.useState([]);
  React.useEffect(() => {
    HTTP.get(`${url}/PurchaseReturnInvoices/${id}`).then((res) => {
      setOrders(res.data.purchaseReturnOrders);
      setInvoice(res.data);
    });
  }, [props]);

  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div>
      <PrintProvider>
        <Print single={true} name="foo">
          <div className={classes.printWrapper}>
            <Page
              invoice={invoice}
              title={translate("resources.root.purchaseReturnInvoice")}
              order={invoice.purchaseReturnOrders}
              human={invoice.supplier}
            />
          </div>
        </Print>
        <NoPrint force>
          <Show>
            <TabbedShowLayout>
              <Tab label={translate("resources.root.invoice")}>
                <TextField
                  source="supplier.supplierName"
                  label={translate("resources.root.supplierName")}
                />
                <TextField
                  source="supplier.owner"
                  label={translate("resources.root.owner")}
                />
                <TextField
                  source="totalQte"
                  label={translate("resources.root.totalQuantity")}
                />
                <TextField
                  source="totalGiftQte"
                  label={translate("resources.root.giftQuantity")}
                />
                <FunctionField
                  label={translate("resources.root.finalQuantity")}
                  render={(record) =>
                    `${record.totalQte + record.totalGiftQte}`
                  }
                />
                <TextField
                  source="note"
                  label={translate("resources.root.note")}
                />
                <TextField
                  source="driver"
                  label={translate("resources.root.driver")}
                />
                {invoice.payByMain ? (
                  <TextField
                    source="mcTotalPrice"
                    label={translate("resources.root.totalPrice")}
                  />
                ) : (
                  <TextField
                    source="scTotalPrice"
                    label={translate("resources.root.totalPrice")}
                  />
                )}
                {invoice.payByMain ? (
                  <TextField
                    source="mcFinalPrice"
                    label={translate("resources.root.finalPrice")}
                  />
                ) : (
                  <TextField
                    source="scFinalPrice"
                    label={translate("resources.root.finalPrice")}
                  />
                )}
                {invoice.payByMain ? (
                  <TextField
                    source="mcFinalDiscount"
                    label={translate("resources.root.finalDiscount")}
                  />
                ) : (
                  <TextField
                    source="scFinalDiscount"
                    label={translate("resources.root.finalDiscount")}
                  />
                )}
              </Tab>
              <Tab label={translate("resources.root.orders")}>
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table
                    stickyHeader
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate("resources.root.barcode")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.name")}
                        </TableCell>
                        {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                          <TableCell align="right">
                            {translate("resources.root.serial")}
                          </TableCell>
                        )}
                        <TableCell align="right">
                          {translate("resources.root.quantity")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.gifts")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.price")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.smallMeasurePrice")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.totalPrice")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.scrollable}>
                      {orders &&
                        orders.map((order) => (
                          <TableRow key={order.product.barcode}>
                            <TableCell component="th" scope="row">
                              {order.product.barcode}
                            </TableCell>
                            <TableCell align="right">
                              <a href={`#/Products/${order.product.id}`}>
                                {order.product.name}
                              </a>
                            </TableCell>
                            {process.env.REACT_APP_PRODUCT_SERIAL ===
                              "true" && (
                              <TableCell align="right">
                                {order.productSerial?.serialNumber}
                              </TableCell>
                            )}
                            <TableCell align="right">
                              {order.qte + " + " + order.smallMeasureQte}
                            </TableCell>
                            <TableCell align="right">
                              {order.giftQte +
                                " + " +
                                order.smallMeasureGiftQte}
                            </TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcPurchasePrice?.toLocaleString()
                                : order.scPurchasePrice?.toLocaleString()}
                            </TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcSmallMeasurePurchasePrice?.toLocaleString()
                                : order.scSmallMeasurePurchasePrice?.toLocaleString()}
                            </TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcLastPrice?.toLocaleString()
                                : order.scLastPrice?.toLocaleString()}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Tab>
            </TabbedShowLayout>
          </Show>
          <Button
            variant="contained"
            className={classes.botton}
            onClick={() => {
              window.print();
            }}
          >
            {translate("resources.root.print")}
          </Button>
          <Button
            variant="contained"
            className={`${classes.botton} ${classes.bottomLeftMargin} mx-3`}
            onClick={() => {
              let ords = orders.map((el) => ({
                productId: el.productId,
                giftQte: el.giftQte,
                scLastPrice: el.scLastPrice,
                mcLastPrice: el.mcLastPrice,
                payByMain: el.payByMain,
                barcode: el.product.barcode,
                name: el.product.name,
                productSerial: el.productSerial,
                qte: el.qte,
                scPurchasePrice: el.scPurchasePrice,
                mcPurchasePrice: el.mcPurchasePrice,
                scDiscount: el.scDiscount,
                mcDiscount: el.mcDiscount,
                scSellPrice: el.scSellPrice,
                mcSellPrice: el.mcSellPrice,
                scTotalPrice: el.scLastPrice,
                mcTotalPrice: el.mcLastPrice,
                note: el.note,
              }));

              if (invoice.payByMain) {
                if (
                  localStorage.getItem("mcPurchaseReturnOrders") &&
                  JSON.parse(localStorage.getItem("mcPurchaseReturnOrders"))
                    .length > 0
                ) {
                  Swal.fire({
                    title: translate(
                      "resources.root.ConfirmDeleteCurrentInvoice"
                    ),
                    showDenyButton: true,
                    confirmButtonColor: "rgba(30, 111, 195, 1)",
                    confirmButtonText: translate("resources.root.copyAnyway"),
                    denyButtonText: translate("resources.root.dontCopy"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire(
                        translate("resources.root.invoiceHasBeenCopied"),
                        "",
                        "success"
                      );
                      localStorage.setItem(
                        "mcPurchaseReturnOrders",
                        JSON.stringify(ords)
                      );
                      window.open("#/PurchaseReturnInvoices/create/1");
                    }
                  });
                } else {
                  localStorage.setItem(
                    "mcPurchaseReturnOrders",
                    JSON.stringify(ords)
                  );
                  window.open("#/PurchaseReturnInvoices/create/1");
                }
              } else {
                if (
                  localStorage.getItem("scPurchaseReturnOrders") &&
                  JSON.parse(localStorage.getItem("scPurchaseReturnOrders"))
                    .length > 0
                ) {
                  Swal.fire({
                    title: translate(
                      "resources.root.ConfirmDeleteCurrentInvoice"
                    ),
                    showDenyButton: true,
                    confirmButtonColor: "rgba(30, 111, 195, 1)",
                    confirmButtonText: translate("resources.root.copyAnyway"),
                    denyButtonText: translate("resources.root.dontCopy"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire(
                        translate("resources.root.invoiceHasBeenCopied"),
                        "",
                        "success"
                      );
                      localStorage.setItem(
                        "scPurchaseReturnOrders",
                        JSON.stringify(ords)
                      );
                      window.open("#/PurchaseReturnInvoices/create");
                    }
                  });
                } else {
                  localStorage.setItem(
                    "scPurchaseReturnOrders",
                    JSON.stringify(ords)
                  );
                  window.open("#/PurchaseReturnInvoices/create");
                }
              }
            }}
          >
            {translate("resources.root.copy")}
          </Button>
        </NoPrint>
      </PrintProvider>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 72,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: 5,
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "350px !important",
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "140%",
      marginLeft: theme.direction === "rtl" ? 0 : -230,
      marginRight: theme.direction === "rtl" ? -230 : -230,
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "80%",
      marginLeft: 265,
      paddingTop: 60,
      //   marginRight: 230,
    },
  },
}));
