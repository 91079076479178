import * as React from "react";
import { useNotify, useTranslate } from "react-admin";
import { url, handleRequestResponse } from "../../request";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import { BiRefresh } from "react-icons/bi";
import { FaSave } from "react-icons/fa";
import { HTTP } from "../../axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Datetime from "react-datetime";
import { FormControlLabel, Switch } from "@mui/material";

export const ProductsCreate = (props) => {
  const [productCategories, setProductCategories] = React.useState([]);
  const [productBrands, setProductBrands] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [category, setCategory] = React.useState(
    parseInt(localStorage.getItem("defaultCategory"))
  );
  const [brand, setBrand] = React.useState(null);
  const [store, setStore] = React.useState(0);
  const [barcode, setBarcode] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [preferredName, setPreferredName] = React.useState(null);
  const [shortDescription, setShortDescription] = React.useState(null);
  const [longDescription, setLongDescription] = React.useState(null);
  const [productMeasure, setProductMeasure] = React.useState(
    localStorage.getItem("productMeasure") ?? "Kg"
  );
  const [weightInKg, setWeightInKg] = React.useState(
    localStorage.getItem("productWeightInKg") ?? 1
  );
  const [oneContains, setOneContains] = React.useState(
    localStorage.getItem("productOneContains") ?? 1
  );
  const [oneMeasure, setOneMeasure] = React.useState(
    localStorage.getItem("productOneMeasure") ?? "Kg"
  );
  const [note, setNote] = React.useState(null);
  const [profitRatio, setProfitRatio] = React.useState(null);
  const [purchasePrice, setPurchasePrice] = React.useState(0);
  const [sellPrice, setSellPrice] = React.useState(0);
  const [smallMeasureSellPrice, setSmallMeasureSellPrice] = React.useState(0);
  const [alertQty, setAlertQty] = React.useState(0);
  const [initialQty, setInitialQty] = React.useState(0);
  const [minSell, setMinSell] = React.useState(0);
  const [maxSell, setMaxSell] = React.useState(0);
  const [productionDate, setProductionDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [expireDate, setExpireDate] = React.useState(
    moment().add(6, "months").format("YYYY-MM-DD")
  );
  const [sellByUsd, setSellByUsd] = React.useState(
    localStorage.getItem("productSellByUSD") === "true" || false
  );
  const [purchaseByUsd, setPurchaseByUsd] = React.useState(
    localStorage.getItem("productPurchaseByUSD") === "true" || false
  );
  const [canSellBelowZero, setCanSellBelowZero] = React.useState(false);
  const [barcode1, setBarcode1] = React.useState(null);
  const [barcode2, setBarcode2] = React.useState(null);
  const [barcode3, setBarcode3] = React.useState(null);
  const [barcode4, setBarcode4] = React.useState(null);
  const [barcode5, setBarcode5] = React.useState(null);
  const [barcode6, setBarcode6] = React.useState(null);
  const [barcode7, setBarcode7] = React.useState(null);
  const [barcode8, setBarcode8] = React.useState(null);
  const [barcode9, setBarcode9] = React.useState(null);
  const [dollarPrice, setDollarPrice] = React.useState(
    localStorage.getItem("dollarPrice") ?? 1450
  );

  const [isLoading, setIsLoading] = React.useState(false);

  const notify = useNotify();
  const translate = useTranslate();

  function handleCreate(redirect = false) {
    HTTP.post(`${url}/Products`, {
      productCategoryId: category,
      brandId: brand,
      barcode: barcode,
      name: name,
      preferName: preferredName,
      shortDescription: shortDescription,
      longDescription: longDescription,
      productMeasure: productMeasure,
      weightInKg: weightInKg,
      oneContains: oneContains,
      oneMeasure: oneMeasure,
      note: note,
      barcode1: barcode1,
      barcode2: barcode2,
      barcode3: barcode3,
      barcode4: barcode4,
      barcode5: barcode5,
      barcode6: barcode6,
      barcode7: barcode7,
      barcode8: barcode8,
      barcode9: barcode9,
      storeProducts: [
        {
          storeId: store,
          mcPurchasePrice: purchasePrice,
          scPurchasePrice: purchasePrice,
          purchaseByMain: purchaseByUsd,
          mcSellPrice: sellPrice,
          scSellPrice: sellPrice,
          mcSmallMeasureSellPrice: smallMeasureSellPrice,
          scSmallMeasureSellPrice: smallMeasureSellPrice,
          mcSellMinPrice: minSell,
          mcSellMaxPrice: maxSell,
          scSellMinPrice: minSell,
          scSellMaxPrice: maxSell,
          sellByMain: sellByUsd,
          initialQte: initialQty,
          alertQte: alertQty,
          productionDate: productionDate,
          expireDate: expireDate,
          canSellBellowZero: canSellBelowZero,
          profitRate: profitRatio / 100,
        },
      ],
    })
      .then((response) => {
        if (redirect) {
          window.location.href = "#/Products";
        }
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
        // document.getElementById("disableOnClick").disabled = false;
        // document.getElementById("disableOnClick2").disabled = false;
        handleRequestResponse(notify, err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  React.useEffect(function () {
    HTTP.get(`${url}/ProductCategories?_end=10000`)
      .then((response) => {
        let newArray = [];
        response.data.map((category) => {
          newArray.push({
            value: category.id,
            label: category.name,
            category: category,
          });
        });
        setProductCategories(newArray);
      })
      .catch((err) => {
        handleRequestResponse(notify, err);
      });

    HTTP.get(`${url}/Brands?_end=10000`)
      .then((response) => {
        let newArray = [];
        response.data.map((brand) => {
          newArray.push({
            value: brand.id,
            label: brand.name,
            brand: brand,
          });
        });
        setProductBrands(newArray);
      })
      .catch((err) => {
        handleRequestResponse(notify, err);
      });

    HTTP.get(`${url}/Stores?_end=10000`)
      .then((response) => {
        let newArray = [];
        response.data.map((store) => {
          newArray.push({
            value: store.id,
            label: store.title,
            store: store,
          });
        });
        setStores(newArray);
      })
      .catch((err) => {
        handleRequestResponse(notify, err);
      });
  }, []);

  React.useEffect(
    function () {
      if (
        profitRatio !== 0 &&
        profitRatio !== null &&
        profitRatio !== undefined &&
        profitRatio !== ""
      ) {
        let addSellPrice = 0;
        if ((purchaseByUsd && sellByUsd) || (!purchaseByUsd && !sellByUsd)) {
          addSellPrice =
            (parseFloat(profitRatio) / 100) * parseFloat(purchasePrice) +
            parseFloat(purchasePrice);
        } else if (!purchaseByUsd && sellByUsd) {
          addSellPrice =
            ((purchasePrice / dollarPrice) * profitRatio) / 100 +
            parseFloat(purchasePrice / dollarPrice);
        } else if (purchaseByUsd && !sellByUsd) {
          addSellPrice =
            parseFloat(purchasePrice * profitRatio * dollarPrice) / 100 +
            parseFloat(purchasePrice * dollarPrice);
        }
        setSellPrice(addSellPrice);
        setSmallMeasureSellPrice((addSellPrice / oneContains).toFixed(3));
      }
    },
    [profitRatio, purchasePrice, purchaseByUsd, sellByUsd]
  );

  React.useEffect(function () {
    console.log(productionDate);
  }, []);

  return (
    <div className={`p-4`}>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 ">
        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.barcode")}&nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setBarcode(e.target.value)}
              placeholder={translate("resources.root.barcode")}
              value={barcode}
            />
          </Col>
          <button
            className="btn btn-outline-primary"
            variant={"outline-primary"}
            onClick={() => {
              HTTP.get("/Products/GenerateNewBarcode")
                .then((response) => {
                  setBarcode(response.data);
                })
                .catch((err) =>
                  notify(err.response.data.message, {
                    type: "error",
                    undoable: true,
                  })
                );
            }}
          >
            <BiRefresh />
          </button>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {process.env.REACT_APP_APP_NAME === "Mediply"
                ? translate("resources.root.scientificName")
                : translate("resources.root.name")}
              &nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => {
                setName(e.target.value);
                setPreferredName(e.target.value);
              }}
              placeholder={
                process.env.REACT_APP_APP_NAME === "Mediply"
                  ? translate("resources.root.scientificName")
                  : translate("resources.root.name")
              }
              value={name}
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {process.env.REACT_APP_APP_NAME === "Mediply"
                ? translate("resources.root.BusinessName")
                : translate("resources.root.preferedName")}
              &nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setPreferredName(e.target.value)}
              placeholder={
                process.env.REACT_APP_APP_NAME === "Mediply"
                  ? translate("resources.root.BusinessName")
                  : translate("resources.root.preferedName")
              }
              value={preferredName}
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.category")}&nbsp;:&nbsp;
            </Form.Label>
            <Select
              isClearable
              isSearchable
              className="basic-multi-select w-100"
              classNamePrefix="select"
              value={productCategories.find((x) => x.value === category)}
              options={productCategories}
              onChange={(e) => {
                setCategory(e ? e.value : 0);
              }}
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.brand")}&nbsp;:&nbsp;
            </Form.Label>
            <Select
              isClearable
              isSearchable
              className="basic-multi-select w-100"
              classNamePrefix="select"
              value={productBrands.find((x) => x.value === brand)}
              options={productBrands}
              onChange={(e) => {
                setBrand(e ? e.value : 0);
              }}
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.shortDescription")}&nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setShortDescription(e.target.value)}
              placeholder={translate("resources.root.shortDescription")}
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.longDescription")}&nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setLongDescription(e.target.value)}
              placeholder={translate("resources.root.longDescription")}
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.productMeasure")}&nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="text"
              defaultValue={productMeasure}
              onChange={(e) => setProductMeasure(e.target.value)}
              placeholder={translate("resources.root.productMeasure")}
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.weightInKg")}&nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="text"
              defaultValue={weightInKg}
              onChange={(e) => setWeightInKg(e.target.value)}
              placeholder={translate("resources.root.weightInKg")}
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.oneContains")}&nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="text"
              defaultValue={oneContains}
              onChange={(e) => {
                setOneContains(e.target.value);
                setSmallMeasureSellPrice(
                  (sellPrice / e.target.value).toFixed(3)
                );
              }}
              placeholder={translate("resources.root.oneContains")}
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.oneMeasure")}&nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="text"
              defaultValue={oneMeasure}
              onChange={(e) => setOneMeasure(e.target.value)}
              placeholder={translate("resources.root.oneMeasure")}
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.note")}&nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setNote(e.target.value)}
              placeholder={translate("resources.root.note")}
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2 p-3 col-12">
          {(localStorage.getItem("role").toLowerCase() === "superadmin" ||
            localStorage.getItem("role").toLowerCase() === "admin" ||
            localStorage.getItem("role").toLowerCase() === "branchadmin") && (
            <Col className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2 text-nowrap">
                {translate("resources.root.stores")}&nbsp;:&nbsp;
              </Form.Label>
              <Select
                isClearable
                isSearchable
                className="basic-multi-select w-100"
                classNamePrefix="select"
                options={stores}
                onChange={(e) => {
                  setStore(e ? e.value : 0);
                }}
              />
            </Col>
          )}
        </div>
        {/* <Col xs={4}>
          <Form.Group as={Row} controlId="profitRatio">
            <Form.Label column sm={3}>
              {translate("resources.root.profitRatio")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="number"
                maxLength={2}
                max={100}
                min={0}
                onChange={(e) => setProfitRatio(e.target.value)}
                placeholder={translate("resources.root.profitRatio")}
              />
            </Col>
          </Form.Group>
        </Col> */}
      </div>
      <hr />
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
        {process.env.REACT_APP_APP_NAME !== "Mediply" && (
          <div className="d-flex justify-content-center align-items-center gap-2 p-3">
            <Col className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2 text-nowrap">
                {translate("resources.root.purchasePrice")}&nbsp;:&nbsp;
              </Form.Label>
              <Form.Control
                type="number"
                min={0}
                onChange={(e) => setPurchasePrice(e.target.value)}
                placeholder={translate("resources.root.purchasePrice")}
              />
            </Col>
          </div>
        )}
        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.sellPrice")}&nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="number"
              min={0}
              onChange={(e) => {
                setSellPrice(e.target.value);
                setSmallMeasureSellPrice(
                  (e.target.value / oneContains).toFixed(3)
                );
              }}
              value={sellPrice}
              placeholder={translate("resources.root.sellPrice")}
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.smallMeasureSellPrice")}&nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="number"
              min={0}
              onChange={(e) => setSmallMeasureSellPrice(e.target.value)}
              placeholder={translate("resources.root.smallMeasureSellPrice")}
              value={smallMeasureSellPrice}
            />
          </Col>
        </div>

        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.alertQty")}&nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="number"
              min={0}
              onChange={(e) => setAlertQty(e.target.value)}
              placeholder={translate("resources.root.alertQty")}
            />
          </Col>
        </div>

        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.initialQty")}&nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="number"
              min={0}
              onChange={(e) => setInitialQty(e.target.value)}
              placeholder={translate("resources.root.initialQty")}
            />
          </Col>
        </div>

        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.productionDate")}&nbsp;:&nbsp;
            </Form.Label>
            <Datetime
              initialValue={productionDate}
              defaultShow={true}
              dateFormat="DD-MM-YYYY"
              className="w-100"
              timeFormat={false}
              placeholder={translate("resources.root.productionDate")}
              onChange={(e) => setProductionDate(e)}
            />
          </Col>
        </div>

        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.minSell")}&nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="number"
              min={0}
              onChange={(e) => {
                setMinSell(e.target.value);
              }}
              value={minSell}
              placeholder={translate("resources.root.minSell")}
            />
          </Col>
        </div>

        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.maxSell")}&nbsp;:&nbsp;
            </Form.Label>
            <Form.Control
              type="number"
              min={0}
              onChange={(e) => {
                setMaxSell(e.target.value);
              }}
              value={maxSell}
              placeholder={translate("resources.root.maxSell")}
            />
          </Col>
        </div>

        <div className="d-flex justify-content-center align-items-center gap-2 p-3">
          <Col className="d-flex align-items-center m-0 p-0">
            <Form.Label className="m-0 mr-2 text-nowrap">
              {translate("resources.root.expireDate")}&nbsp;:&nbsp;
            </Form.Label>
            <Datetime
              initialValue={expireDate}
              className="w-100"
              defaultShow={true}
              dateFormat="DD-MM-YYYY"
              timeFormat={false}
              placeholder={translate("resources.root.expireDate")}
              onChange={(e) => setExpireDate(e)}
            />
          </Col>
        </div>

        <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center p-3">
          <FormControlLabel
            className="m-0 text-nowrap "
            control={
              <Switch
                onChange={(e) => setPurchaseByUsd(e.target.checked)}
                defaultChecked={purchaseByUsd}
              />
            }
            label={translate("resources.root.purchaseByUsd")}
          />
        </InputGroup>

        <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center p-3">
          <FormControlLabel
            className="m-0 text-nowrap "
            control={
              <Switch
                onChange={(e) => setSellByUsd(e.target.checked)}
                defaultChecked={sellByUsd}
              />
            }
            label={translate("resources.root.sellByUsd")}
          />
        </InputGroup>

        <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center p-3">
          <FormControlLabel
            className="m-0 text-nowrap "
            control={
              <Switch
                type="checkbox"
                onChange={(e) => setCanSellBelowZero(e.target.checked)}
                defaultChecked={canSellBelowZero}
              />
            }
            label={translate("resources.root.canSellBelowZero")}
          />
        </InputGroup>
      </div>
      <Row>
        <Col>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontWeight: "bold" }}>
                {translate("resources.root.barcodes")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                <div className="d-flex justify-content-center align-items-center gap-2 p-3">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2 text-nowrap">
                      {translate("resources.root.barcode1")}&nbsp;:&nbsp;
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setBarcode1(e.target.value)}
                      placeholder={translate("resources.root.barcode")}
                      value={barcode1}
                    />
                  </Col>
                  <button
                    className="btn btn-outline-primary"
                    variant={"outline-primary"}
                    onClick={() => {
                      HTTP.get("/Products/GenerateNewBarcode")
                        .then((response) => {
                          setBarcode1(response.data);
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                    }}
                  >
                    <BiRefresh />
                  </button>
                </div>

                <div className="d-flex justify-content-center align-items-center gap-2 p-3">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2 text-nowrap">
                      {translate("resources.root.barcode2")}&nbsp;:&nbsp;
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setBarcode2(e.target.value)}
                      placeholder={translate("resources.root.barcode")}
                      value={barcode2}
                    />
                  </Col>
                  <button
                    className="btn btn-outline-primary"
                    variant={"outline-primary"}
                    onClick={() => {
                      HTTP.get("/Products/GenerateNewBarcode")
                        .then((response) => {
                          setBarcode2(response.data);
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                    }}
                  >
                    <BiRefresh />
                  </button>
                </div>

                <div className="d-flex justify-content-center align-items-center gap-2 p-3">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2 text-nowrap">
                      {translate("resources.root.barcode3")}&nbsp;:&nbsp;
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setBarcode3(e.target.value)}
                      placeholder={translate("resources.root.barcode")}
                      value={barcode3}
                    />
                  </Col>
                  <button
                    className="btn btn-outline-primary"
                    variant={"outline-primary"}
                    onClick={() => {
                      HTTP.get("/Products/GenerateNewBarcode")
                        .then((response) => {
                          setBarcode3(response.data);
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                    }}
                  >
                    <BiRefresh />
                  </button>
                </div>

                <div className="d-flex justify-content-center align-items-center gap-2 p-3">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2 text-nowrap">
                      {translate("resources.root.barcode4")}&nbsp;:&nbsp;
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setBarcode4(e.target.value)}
                      placeholder={translate("resources.root.barcode")}
                      value={barcode4}
                    />
                  </Col>
                  <button
                    className="btn btn-outline-primary"
                    variant={"outline-primary"}
                    onClick={() => {
                      HTTP.get("/Products/GenerateNewBarcode")
                        .then((response) => {
                          setBarcode4(response.data);
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                    }}
                  >
                    <BiRefresh />
                  </button>
                </div>

                <div className="d-flex justify-content-center align-items-center gap-2 p-3">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2 text-nowrap">
                      {translate("resources.root.barcode5")}&nbsp;:&nbsp;
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setBarcode5(e.target.value)}
                      placeholder={translate("resources.root.barcode")}
                      value={barcode5}
                    />
                  </Col>
                  <button
                    className="btn btn-outline-primary"
                    variant={"outline-primary"}
                    onClick={() => {
                      HTTP.get("/Products/GenerateNewBarcode")
                        .then((response) => {
                          setBarcode5(response.data);
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                    }}
                  >
                    <BiRefresh />
                  </button>
                </div>

                <div className="d-flex justify-content-center align-items-center gap-2 p-3">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2 text-nowrap">
                      {translate("resources.root.barcode6")}&nbsp;:&nbsp;
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setBarcode6(e.target.value)}
                      placeholder={translate("resources.root.barcode")}
                      value={barcode6}
                    />
                  </Col>
                  <button
                    className="btn btn-outline-primary"
                    variant={"outline-primary"}
                    onClick={() => {
                      HTTP.get("/Products/GenerateNewBarcode")
                        .then((response) => {
                          setBarcode6(response.data);
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                    }}
                  >
                    <BiRefresh />
                  </button>
                </div>

                <div className="d-flex justify-content-center align-items-center gap-2 p-3">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2 text-nowrap">
                      {translate("resources.root.barcode7")}&nbsp;:&nbsp;
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setBarcode7(e.target.value)}
                      placeholder={translate("resources.root.barcode")}
                      value={barcode7}
                    />
                  </Col>
                  <button
                    className="btn btn-outline-primary"
                    variant={"outline-primary"}
                    onClick={() => {
                      HTTP.get("/Products/GenerateNewBarcode")
                        .then((response) => {
                          setBarcode7(response.data);
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                    }}
                  >
                    <BiRefresh />
                  </button>
                </div>

                <div className="d-flex justify-content-center align-items-center gap-2 p-3">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2 text-nowrap">
                      {translate("resources.root.barcode8")}&nbsp;:&nbsp;
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setBarcode8(e.target.value)}
                      placeholder={translate("resources.root.barcode")}
                      value={barcode8}
                    />
                  </Col>
                  <button
                    className="btn btn-outline-primary"
                    variant={"outline-primary"}
                    onClick={() => {
                      HTTP.get("/Products/GenerateNewBarcode")
                        .then((response) => {
                          setBarcode8(response.data);
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                    }}
                  >
                    <BiRefresh />
                  </button>
                </div>

                <div className="d-flex justify-content-center align-items-center gap-2 p-3">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2 text-nowrap">
                      {translate("resources.root.barcode9")}&nbsp;:&nbsp;
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setBarcode9(e.target.value)}
                      placeholder={translate("resources.root.barcode")}
                      value={barcode9}
                    />
                  </Col>
                  <button
                    className="btn btn-outline-primary"
                    variant={"outline-primary"}
                    onClick={() => {
                      HTTP.get("/Products/GenerateNewBarcode")
                        .then((response) => {
                          setBarcode9(response.data);
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                    }}
                  >
                    <BiRefresh />
                  </button>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </Col>
      </Row>
      <hr />
      <div className="d-flex justify-content-center align-content-center gap-5">
        <button
          disabled={isLoading}
          className="btn btn-primary btn-block d-flex justify-content-center align-items-center gap-1 m-0"
          onClick={() => {
            setIsLoading(true);
            handleCreate();
          }}
        >
          <FaSave /> {translate("resources.root.create")}
        </button>
        <button
          disabled={isLoading}
          className="btn btn-outline-primary btn-block d-flex justify-content-center align-items-center gap-1 m-0"
          onClick={() => {
            setIsLoading(true);
            handleCreate(true);
          }}
        >
          <FaSave /> {translate("resources.root.createAndGoToList")}
        </button>
      </div>
    </div>
  );
};
