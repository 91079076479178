import { jssPreset, makeStyles, StylesProvider, withStyles } from "@mui/styles";
import { Button, Card } from "react-bootstrap";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import logos from "../../assets/logos";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useRedirect, useTranslate } from "react-admin";
import { create } from "jss";
import rtl from "jss-rtl";
import { Col, Form, Row } from "react-bootstrap";
import { FaDownload, FaUpload } from "react-icons/fa";
import { url, handleRequestResponse } from "../../request";
import Swal from "sweetalert2";
import { HTTP } from "../../axios";

const InvoiceMockup = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const locale =
    localStorage.getItem("locale") ?? process.env.REACT_APP_MEDICAL_MODULE
      ? "enHana"
      : "en";
  const [logoUrl, setLogoUrl] = React.useState(
    localStorage.getItem("logoUrl") ?? logos.logo
  );
  const [invoiceHeaderHeight, setInvoiceHeaderHeight] = React.useState(
    parseInt(localStorage.getItem("invoiceHeaderHeight") ?? 175)
  );
  const [logoMarginTop, setLogoMarginTop] = React.useState(
    parseInt(localStorage.getItem("logoMarginTop") ?? 275)
  );
  const [logoMarginXAxis, setLogoMarginXAxis] = React.useState(
    parseInt(localStorage.getItem("logoMarginXAxis") ?? 20)
  );
  const [logoMarginBottom, setLogoMarginBottom] = React.useState(
    parseInt(localStorage.getItem("logoMarginBottom") ?? 160)
  );
  const [logoWidth, setLogoWidth] = React.useState(
    parseInt(localStorage.getItem("logoWidth") ?? 100)
  );
  const [logoHeight, setLogoHeight] = React.useState(
    parseInt(localStorage.getItem("logoHeight") ?? 100)
  );
  const [logoRadius, setLogoRadius] = React.useState(
    parseInt(localStorage.getItem("logoRadius") ?? 50)
  );

  const [phonesMarginTop, setPhonesMarginTop] = React.useState(
    parseInt(localStorage.getItem("phonesMarginTop") ?? 275)
  );
  const [phonesMarginXAxis, setPhonesMarginXAxis] = React.useState(
    parseInt(localStorage.getItem("phonesMarginXAxis") ?? 145)
  );
  const [phonesMarginBottom, setPhonesMarginBottom] = React.useState(
    parseInt(localStorage.getItem("phonesMarginBottom") ?? 160)
  );
  const [phonesFontSize, setPhonesFontSize] = React.useState(
    parseInt(localStorage.getItem("phonesFontSize") ?? 18)
  );

  const [infoMarginTop, setInfoMarginTop] = React.useState(
    parseInt(localStorage.getItem("infoMarginTop") ?? -7)
  );
  const [infoMarginXAxis, setInfoMarginXAxis] = React.useState(
    parseInt(localStorage.getItem("infoMarginXAxis") ?? 620)
  );
  const [infoMarginBottom, setInfoMarginBottom] = React.useState(
    parseInt(localStorage.getItem("infoMarginBottom") ?? 0)
  );
  const [infoFontSize, setInfoFontSize] = React.useState(
    parseInt(localStorage.getItem("infoFontSize") ?? 18)
  );

  const [invoiceDescriptionMarginTop, setInvoiceDescriptionMarginTop] =
    React.useState(
      parseInt(localStorage.getItem("invoiceDescriptionMarginTop") ?? 110)
    );
  const [invoiceDescriptionMarginXAxis, setInvoiceDescriptionMarginXAxis] =
    React.useState(
      parseInt(localStorage.getItem("invoiceDescriptionMarginXAxis") ?? 30)
    );
  const [invoiceDescriptionMarginBottom, setInvoiceDescriptionMarginBottom] =
    React.useState(
      parseInt(localStorage.getItem("invoiceDescriptionMarginBottom") ?? 0)
    );
  const [invoiceDescriptionFontSize, setInvoiceDescriptionFontSize] =
    React.useState(
      parseInt(localStorage.getItem("invoiceDescriptionFontSize") ?? 18)
    );

  const [showGift, setShowGift] = React.useState(
    localStorage.getItem("showGift") === "true" || false
  );

  const [jss, setJss] = React.useState(
    create({ plugins: [...jssPreset().plugins, rtl()] })
  );
  const redirect = useRedirect();

  return (
    <StylesProvider jss={jss}>
      <Grid container>
        <Grid item xs={9}>
          <Card
            className={classes.root}
            variant="outlined"
            style={{ fontSize: 20 }}
          >
            <CardContent style={{ padding: 0 }}>
              <div className={classes.gridContainer}>
                <Grid container spacing={0} style={{ textAlign: "start" }}>
                  <Grid item xs={12} style={{ height: invoiceHeaderHeight }}>
                    <div>
                      <Paper elevation={0} className={classes.paper}>
                        <div className={classes.logoWrapper}>
                          <div
                            style={{
                              width: logoWidth,
                              height: logoHeight,
                              borderRadius: logoRadius + "%",
                              overflow: "hidden",
                              position: "absolute",
                              marginTop: logoMarginTop + "px" ?? "275px",
                              marginRight: logoMarginXAxis + "px" ?? "20px",
                              marginBottom: logoMarginBottom + "px" ?? "160px",
                              marginLeft: logoMarginXAxis + "px" ?? "20px",
                            }}
                          >
                            {process.env.REACT_APP_SHOW_LOGO === "true" && (
                              <img
                                alt=""
                                src={logoUrl}
                                className={classes.img}
                              />
                            )}
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              fontSize: phonesFontSize + "px" ?? "18px",
                              marginTop: phonesMarginTop + "px" ?? "275px",
                              marginRight: phonesMarginXAxis + "px" ?? "145px",
                              marginBottom:
                                phonesMarginBottom + "px" ?? "160px",
                              marginLeft: phonesMarginXAxis + "px" ?? "145px",
                            }}
                          >
                            <div>
                              {locale === "en"
                                ? localStorage.getItem("addressInEnglish")
                                : locale === "kr"
                                ? localStorage.getItem("addressInKurdish")
                                : localStorage.getItem("addressInArabic")}
                            </div>
                            <div>
                              {localStorage.getItem("useCustomPhoneNumbers") ===
                              "true"
                                ? localStorage.getItem("phoneNumber1")
                                : process.env.REACT_APP_PHONE}
                            </div>
                            <div>
                              {localStorage.getItem("useCustomPhoneNumbers") ===
                              "true"
                                ? localStorage.getItem("phoneNumber2")
                                : process.env.REACT_APP_PHONE2}
                            </div>
                          </div>
                        </div>
                      </Paper>
                    </div>
                    <div>
                      <Paper
                        elevation={0}
                        style={{
                          position: "absolute",
                          width: "35%",
                          fontSize: infoFontSize + "px" ?? "18px",
                          marginTop: infoMarginTop + "px" ?? "-7px",
                          marginRight: infoMarginXAxis + "px" ?? "620px",
                          marginBottom: infoMarginBottom + "px" ?? "0px",
                          marginLeft: infoMarginXAxis + "px" ?? "620px",
                        }}
                      >
                        <h3>{translate("resources.root.invoice")}</h3>
                        <div>
                          {translate("resources.root.date")}: <b>2021-05-31</b>
                        </div>
                        <div>
                          {translate("resources.root.invoiceNumber")}:{" "}
                          <b>15248</b>
                        </div>
                        <div>
                          {translate("resources.root.id")}: <b>125</b>
                        </div>
                        <div></div>
                      </Paper>
                    </div>
                    <div>
                      <Paper
                        elevation={0}
                        style={{
                          position: "absolute",
                          width: "40%",
                          fontSize: invoiceDescriptionFontSize + "px" ?? "18px",
                          marginTop:
                            invoiceDescriptionMarginTop + "px" ?? "110px",
                          marginRight:
                            invoiceDescriptionMarginXAxis + "px" ?? "30px",
                          marginBottom:
                            invoiceDescriptionMarginBottom + "px" ?? "0px",
                          marginLeft:
                            invoiceDescriptionMarginXAxis + "px" ?? "30px",
                        }}
                      >
                        <p>
                          {locale === "en"
                            ? localStorage.getItem(
                                "invoiceDescriptionInEnglish"
                              )
                            : locale === "kr"
                            ? localStorage.getItem(
                                "invoiceDescriptionInKurdish"
                              )
                            : localStorage.getItem(
                                "invoiceDescriptionInArabic"
                              )}
                        </p>
                      </Paper>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper elevation={0} className={classes.paper}>
                      <div className={classes.flexContainer}>
                        <div className={classes.flexCol}>
                          <div>
                            {translate("resources.root.name")}:{" "}
                            <b>Customer Name</b>
                          </div>
                          <div>
                            {translate("resources.root.address")}:{" "}
                            <b>Customer Address</b>
                          </div>
                        </div>
                        <div className={classes.flexCol}>
                          <div>
                            {translate("resources.root.phone")}:{" "}
                            <b>{`07701234567`}</b>
                          </div>
                          <div>
                            {translate("resources.root.driver")}:{" "}
                            <b>{`Driver Name`}</b>
                          </div>
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper elevation={0} className={classes.paper}>
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          size="small"
                          aria-label="customized table"
                        >
                          <TableRow
                            style={{
                              backgroundColor: "#ff5252",
                              fontSize: "18px",
                              color: "black",
                            }}
                          >
                            <StyledTableCell align="left">#</StyledTableCell>
                            <StyledTableCell align="left">
                              <b>{translate("resources.root.barcode")}</b>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <b>{translate("resources.root.name")}</b>
                            </StyledTableCell>
                            <StyledTableCell align="left"> </StyledTableCell>
                            <StyledTableCell align="left"> </StyledTableCell>
                            <StyledTableCell align="right">
                              <b>{translate("resources.root.quantity")}</b>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <b>{translate("resources.root.price")}</b>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <b>{translate("resources.root.smallMeasure")}</b>
                            </StyledTableCell>
                            {showGift === true ? (
                              <StyledTableCell align="right">
                                <b>{translate("resources.root.gift")}</b>
                              </StyledTableCell>
                            ) : (
                              ""
                            )}
                            <StyledTableCell align="right">
                              <b>{translate("resources.root.totalPrice")}</b>
                            </StyledTableCell>
                          </TableRow>
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell align="left">1</StyledTableCell>
                              <StyledTableCell align="left">
                                132456
                              </StyledTableCell>
                              <StyledTableCell align="left" colSpan="3">
                                Test Name
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                12
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                $18
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                $12
                              </StyledTableCell>
                              {showGift === true ? (
                                <StyledTableCell align="right">
                                  0
                                </StyledTableCell>
                              ) : (
                                ""
                              )}
                              <StyledTableCell align="right">
                                30
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell align="left">2</StyledTableCell>
                              <StyledTableCell align="left">
                                9871
                              </StyledTableCell>
                              <StyledTableCell align="left" colSpan="3">
                                Test Name 2
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                15
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                10
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                $8
                              </StyledTableCell>
                              {showGift === true ? (
                                <StyledTableCell align="right">
                                  4
                                </StyledTableCell>
                              ) : (
                                ""
                              )}
                              <StyledTableCell align="right">
                                15
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell align="left">3</StyledTableCell>
                              <StyledTableCell align="left">
                                12547
                              </StyledTableCell>
                              <StyledTableCell align="left" colSpan="3">
                                Test Name 3
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                10
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                $35
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                $28
                              </StyledTableCell>
                              {showGift === true ? (
                                <StyledTableCell align="right">
                                  1
                                </StyledTableCell>
                              ) : (
                                ""
                              )}
                              <StyledTableCell align="right">
                                30
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper elevation={0} className={classes.rPaper}>
                      <div>
                        {translate("resources.root.totalPrice")}: <b>123487</b>
                      </div>
                      <div>
                        {translate("resources.root.discount")}: <b>10</b>
                      </div>
                      <div>
                        {translate("resources.root.previousPrice")}: <b>2500</b>
                      </div>
                      <div>
                        {translate("resources.root.purePrice")}: <b>1350</b>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper elevation={0} className={classes.centerPaper}>
                      <div>
                        {translate("resources.root.totalNumber")}: <b>15</b>
                      </div>
                      <div>
                        {translate("resources.root.currentLoan")}: <b>12</b>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper elevation={0} className={classes.paper}>
                      <div>
                        {translate("resources.root.note")}: <b>Invoice Notes</b>
                      </div>
                    </Paper>
                  </Grid>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <Grid item xs={6}>
                    <Paper elevation={0} className={classes.centerPaper}>
                      {translate("resources.root.accountant")}
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper elevation={0} className={classes.centerPaper}>
                      Customer Name
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            overflowY: "auto",
            height: "800px",
            padding: "3px",
            textAlign: "start",
          }}
        >
          <Card border="dark">
            <Card.Header>{translate("resources.root.general")}</Card.Header>
            <Card.Body>
              <div
                style={{ borderRadius: "10px", padding: "5px", margin: "3px" }}
              >
                <Form.Group as={Row} controlId="invoiceHeaderHeight">
                  <Form.Label column sm={7}>
                    {translate("resources.root.invoiceHeaderHeight")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate(
                        "resources.root.invoiceHeaderHeight"
                      )}
                      onChange={(e) => {
                        setInvoiceHeaderHeight(parseInt(e.target.value));
                        localStorage.setItem(
                          "invoiceHeaderHeight",
                          e.target.value
                        );
                      }}
                      defaultValue={invoiceHeaderHeight}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="logoUrl">
                  <Form.Label column sm={4}>
                    {translate("resources.root.logoUrl")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder={translate("resources.root.logoUrl")}
                      onChange={(e) => {
                        if (e.target.value) {
                          setLogoUrl(e.target.value);
                          localStorage.setItem("logoUrl", e.target.value);
                        }
                      }}
                      defaultValue={logoUrl}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="showGift">
                  <Form.Check
                    id={"showGift"}
                    type={"switch"}
                    label={translate("resources.root.showGift")}
                    defaultChecked={showGift}
                    onChange={(e) => {
                      setShowGift(e.target.checked);
                      localStorage.setItem("showGift", e.target.checked);
                    }}
                  />
                </Form.Group>
              </div>
            </Card.Body>
          </Card>
          <Card border="dark">
            <Card.Header>{translate("resources.root.logo")}</Card.Header>
            <Card.Body>
              <div
                style={{ borderRadius: "10px", padding: "5px", margin: "3px" }}
              >
                <Form.Group as={Row} controlId="logoMarginTop">
                  <Form.Label column sm={7}>
                    {translate("resources.root.marginTop")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.marginTop")}
                      onChange={(e) => {
                        setLogoMarginTop(parseInt(e.target.value));
                        localStorage.setItem("logoMarginTop", e.target.value);
                      }}
                      defaultValue={logoMarginTop}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="logoMarginXAxis">
                  <Form.Label column sm={7}>
                    {translate("resources.root.marginXAxis")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.marginXAxis")}
                      onChange={(e) => {
                        setLogoMarginXAxis(parseInt(e.target.value));
                        localStorage.setItem("logoMarginXAxis", e.target.value);
                      }}
                      defaultValue={logoMarginXAxis}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="logoMarginBottom">
                  <Form.Label column sm={7}>
                    {translate("resources.root.marginBottom")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.marginBottom")}
                      onChange={(e) => {
                        setLogoMarginBottom(parseInt(e.target.value));
                        localStorage.setItem(
                          "logoMarginBottom",
                          e.target.value
                        );
                      }}
                      defaultValue={logoMarginBottom}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="logoWidth">
                  <Form.Label column sm={7}>
                    {translate("resources.root.width")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.width")}
                      onChange={(e) => {
                        setLogoWidth(parseInt(e.target.value));
                        localStorage.setItem("logoWidth", e.target.value);
                      }}
                      defaultValue={logoWidth}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="logoHeight">
                  <Form.Label column sm={7}>
                    {translate("resources.root.height")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.height")}
                      onChange={(e) => {
                        setLogoHeight(parseInt(e.target.value));
                        localStorage.setItem("logoHeight", e.target.value);
                      }}
                      defaultValue={logoHeight}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="logoRadius">
                  <Form.Label column sm={7}>
                    {translate("resources.root.radius")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.radius")}
                      onChange={(e) => {
                        setLogoRadius(parseInt(e.target.value));
                        localStorage.setItem("logoRadius", e.target.value);
                      }}
                      defaultValue={logoRadius}
                      onFocus={(e) => e.target.select()}
                      max={50}
                      min={0}
                      step={1}
                    />
                  </Col>
                </Form.Group>
              </div>
            </Card.Body>
          </Card>
          <Card border="dark">
            <Card.Header>{translate("resources.root.phone")}</Card.Header>
            <Card.Body>
              <div
                style={{ borderRadius: "10px", padding: "5px", margin: "3px" }}
              >
                <Form.Group as={Row} controlId="phonesFontSize">
                  <Form.Label column sm={7}>
                    {translate("resources.root.fontSize")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.fontSize")}
                      onChange={(e) => {
                        setPhonesFontSize(parseInt(e.target.value));
                        localStorage.setItem("phonesFontSize", e.target.value);
                      }}
                      defaultValue={phonesFontSize}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="phonesMarginTop">
                  <Form.Label column sm={7}>
                    {translate("resources.root.marginTop")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.marginTop")}
                      onChange={(e) => {
                        setPhonesMarginTop(parseInt(e.target.value));
                        localStorage.setItem("phonesMarginTop", e.target.value);
                      }}
                      defaultValue={phonesMarginTop}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="phonesMarginXAxis">
                  <Form.Label column sm={7}>
                    {translate("resources.root.marginXAxis")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.marginXAxis")}
                      onChange={(e) => {
                        setPhonesMarginXAxis(parseInt(e.target.value));
                        localStorage.setItem(
                          "phonesMarginXAxis",
                          e.target.value
                        );
                      }}
                      defaultValue={phonesMarginXAxis}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="phonesMarginBottom">
                  <Form.Label column sm={7}>
                    {translate("resources.root.marginBottom")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.marginBottom")}
                      onChange={(e) => {
                        setPhonesMarginBottom(parseInt(e.target.value));
                        localStorage.setItem(
                          "phonesMarginBottom",
                          e.target.value
                        );
                      }}
                      defaultValue={phonesMarginBottom}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
              </div>
            </Card.Body>
          </Card>
          <Card border="dark">
            <Card.Header>{translate("resources.root.info")}</Card.Header>
            <Card.Body>
              <div
                style={{ borderRadius: "10px", padding: "5px", margin: "3px" }}
              >
                <Form.Group as={Row} controlId="infoFontSize">
                  <Form.Label column sm={7}>
                    {translate("resources.root.fontSize")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.fontSize")}
                      onChange={(e) => {
                        setInfoFontSize(parseInt(e.target.value));
                        localStorage.setItem("infoFontSize", e.target.value);
                      }}
                      defaultValue={infoFontSize}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="infoMarginTop">
                  <Form.Label column sm={7}>
                    {translate("resources.root.marginTop")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.marginTop")}
                      onChange={(e) => {
                        setInfoMarginTop(parseInt(e.target.value));
                        localStorage.setItem("infoMarginTop", e.target.value);
                      }}
                      defaultValue={infoMarginTop}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="infoMarginXAxis">
                  <Form.Label column sm={7}>
                    {translate("resources.root.marginXAxis")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.marginXAxis")}
                      onChange={(e) => {
                        setInfoMarginXAxis(parseInt(e.target.value));
                        localStorage.setItem("infoMarginXAxis", e.target.value);
                      }}
                      defaultValue={infoMarginXAxis}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="infoMarginBottom">
                  <Form.Label column sm={7}>
                    {translate("resources.root.marginBottom")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.marginBottom")}
                      onChange={(e) => {
                        setInfoMarginBottom(parseInt(e.target.value));
                        localStorage.setItem(
                          "infoMarginBottom",
                          e.target.value
                        );
                      }}
                      defaultValue={infoMarginBottom}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
              </div>
            </Card.Body>
          </Card>
          <Card border="dark">
            <Card.Header>
              {translate("resources.root.invoiceDescription")}
            </Card.Header>
            <Card.Body>
              <div
                style={{ borderRadius: "10px", padding: "5px", margin: "3px" }}
              >
                <Form.Group as={Row} controlId="invoiceDescriptionFontSize">
                  <Form.Label column sm={7}>
                    {translate("resources.root.fontSize")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.fontSize")}
                      onChange={(e) => {
                        setInvoiceDescriptionFontSize(parseInt(e.target.value));
                        localStorage.setItem(
                          "invoiceDescriptionFontSize",
                          e.target.value
                        );
                      }}
                      defaultValue={invoiceDescriptionFontSize}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="invoiceDescriptionMarginTop">
                  <Form.Label column sm={7}>
                    {translate("resources.root.marginTop")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.marginTop")}
                      onChange={(e) => {
                        setInvoiceDescriptionMarginTop(
                          parseInt(e.target.value)
                        );
                        localStorage.setItem(
                          "invoiceDescriptionMarginTop",
                          e.target.value
                        );
                      }}
                      defaultValue={invoiceDescriptionMarginTop}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="invoiceDescriptionMarginXAxis">
                  <Form.Label column sm={7}>
                    {translate("resources.root.marginXAxis")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.marginXAxis")}
                      onChange={(e) => {
                        setInvoiceDescriptionMarginXAxis(
                          parseInt(e.target.value)
                        );
                        localStorage.setItem(
                          "invoiceDescriptionMarginXAxis",
                          e.target.value
                        );
                      }}
                      defaultValue={invoiceDescriptionMarginXAxis}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="invoiceDescriptionMarginBottom">
                  <Form.Label column sm={7}>
                    {translate("resources.root.marginBottom")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder={translate("resources.root.marginBottom")}
                      onChange={(e) => {
                        setInvoiceDescriptionMarginBottom(
                          parseInt(e.target.value)
                        );
                        localStorage.setItem(
                          "invoiceDescriptionMarginBottom",
                          e.target.value
                        );
                      }}
                      defaultValue={invoiceDescriptionMarginBottom}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
              </div>
            </Card.Body>
          </Card>
          <Card border="dark">
            <Card.Header>
              {translate("resources.root.infoNeedRefresh")}
            </Card.Header>
            <Card.Body>
              <div
                style={{ borderRadius: "10px", padding: "5px", margin: "3px" }}
              >
                <Form.Group as={Row} controlId="addressInKurdish">
                  <Form.Label column sm={12}>
                    {translate("resources.root.addressInKurdish")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder={translate("resources.root.addressInKurdish")}
                      onChange={(e) => {
                        localStorage.setItem(
                          "addressInKurdish",
                          e.target.value
                        );
                      }}
                      defaultValue={
                        localStorage.getItem("addressInKurdish") ?? ""
                      }
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="addressInArabic">
                  <Form.Label column sm={12}>
                    {translate("resources.root.addressInArabic")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder={translate("resources.root.addressInArabic")}
                      onChange={(e) => {
                        localStorage.setItem("addressInArabic", e.target.value);
                      }}
                      defaultValue={
                        localStorage.getItem("addressInArabic") ?? ""
                      }
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="addressInEnglish">
                  <Form.Label column sm={12}>
                    {translate("resources.root.addressInEnglish")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder={translate("resources.root.addressInEnglish")}
                      onChange={(e) => {
                        localStorage.setItem(
                          "addressInEnglish",
                          e.target.value
                        );
                      }}
                      defaultValue={
                        localStorage.getItem("addressInEnglish") ?? ""
                      }
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
              </div>
              <div
                style={{ borderRadius: "10px", padding: "5px", margin: "3px" }}
              >
                <Form.Group as={Row} controlId="invoiceDescriptionInKurdish">
                  <Form.Label column sm={12}>
                    {translate("resources.root.invoiceDescriptionInKurdish")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder={translate(
                        "resources.root.invoiceDescriptionInKurdish"
                      )}
                      onChange={(e) => {
                        localStorage.setItem(
                          "invoiceDescriptionInKurdish",
                          e.target.value
                        );
                      }}
                      defaultValue={
                        localStorage.getItem("invoiceDescriptionInKurdish") ??
                        ""
                      }
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="invoiceDescriptionInArabic">
                  <Form.Label column sm={12}>
                    {translate("resources.root.invoiceDescriptionInArabic")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder={translate(
                        "resources.root.invoiceDescriptionInArabic"
                      )}
                      onChange={(e) => {
                        localStorage.setItem(
                          "invoiceDescriptionInArabic",
                          e.target.value
                        );
                      }}
                      defaultValue={
                        localStorage.getItem("invoiceDescriptionInArabic") ?? ""
                      }
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="invoiceDescriptionInEnglish">
                  <Form.Label column sm={12}>
                    {translate("resources.root.invoiceDescriptionInEnglish")}
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder={translate(
                        "resources.root.invoiceDescriptionInEnglish"
                      )}
                      onChange={(e) => {
                        localStorage.setItem(
                          "invoiceDescriptionInEnglish",
                          e.target.value
                        );
                      }}
                      defaultValue={
                        localStorage.getItem("invoiceDescriptionInEnglish") ??
                        ""
                      }
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Form.Group>
              </div>
            </Card.Body>
          </Card>
          <Card border="dark">
            <Card.Header>
              {translate("resources.root.uploadAndDownloadMockup")}
            </Card.Header>
            <Card.Body>
              <div
                style={{ borderRadius: "10px", padding: "5px", margin: "3px" }}
              >
                <Button
                  block
                  variant={"outline-primary"}
                  onClick={() => {
                    HTTP.post(
                      `${url}/DbPrefenses/PostInvoiceSettings`,
                      '"' +
                        JSON.stringify({
                          invoiceHeaderHeight,
                          logoUrl,
                          logoMarginTop,
                          logoMarginXAxis,
                          logoMarginBottom,
                          logoWidth,
                          logoHeight,
                          logoRadius,
                          phonesFontSize,
                          phonesMarginTop,
                          phonesMarginXAxis,
                          phonesMarginBottom,
                          infoFontSize,
                          infoMarginTop,
                          infoMarginXAxis,
                          infoMarginBottom,
                          invoiceDescriptionFontSize,
                          invoiceDescriptionMarginTop,
                          invoiceDescriptionMarginXAxis,
                          invoiceDescriptionMarginBottom,
                        }).replaceAll('"', "'") +
                        '"'
                    )
                      .then((response) => {
                        Swal.fire({
                          position: "top-end",
                          icon: "success",
                          title: translate(
                            "resources.root.uploadedSuccessfully"
                          ),
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      })
                      .catch((error) => {
                        if (error.response.status === 401) {
                          redirect("/Login");
                        }
                        Swal.fire({
                          position: "top-end",
                          icon: "error",
                          title: translate("resources.root.someErrorOccurred"),
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      });
                  }}
                  size={"lg"}
                >
                  <FaUpload /> {translate("resources.root.upload")}
                </Button>
                <Button
                  block
                  variant={"outline-success"}
                  onClick={() => {
                    HTTP.get(`${url}/DbPrefenses/GetInvoiceSettings`)
                      .then((response) => {
                        let data = JSON.parse(
                          response.data.replaceAll("'", '"')
                        );
                        setInvoiceHeaderHeight(data.invoiceHeaderHeight);
                        localStorage.setItem(
                          "invoiceHeaderHeight",
                          data.invoiceHeaderHeight
                        );
                        if (data.logoUrl) {
                          setLogoUrl(data.logoUrl);
                          localStorage.setItem("logoUrl", data.logoUrl);
                        }
                        setLogoMarginTop(data.logoMarginTop);
                        localStorage.setItem(
                          "logoMarginTop",
                          data.logoMarginTop
                        );
                        setLogoMarginXAxis(data.logoMarginXAxis);
                        localStorage.setItem(
                          "logoMarginXAxis",
                          data.logoMarginXAxis
                        );
                        setLogoMarginBottom(data.logoMarginBottom);
                        localStorage.setItem(
                          "logoMarginBottom",
                          data.logoMarginBottom
                        );
                        setLogoWidth(data.logoWidth);
                        localStorage.setItem("logoWidth", data.logoWidth);
                        setLogoHeight(data.logoHeight);
                        localStorage.setItem("logoHeight", data.logoHeight);
                        setLogoRadius(data.logoRadius);
                        localStorage.setItem("logoRadius", data.logoRadius);

                        setPhonesFontSize(data.phonesFontSize);
                        localStorage.setItem(
                          "phonesFontSize",
                          data.phonesFontSize
                        );
                        setPhonesMarginTop(data.phonesMarginTop);
                        localStorage.setItem(
                          "phonesMarginTop",
                          data.phonesMarginTop
                        );
                        setPhonesMarginXAxis(data.phonesMarginXAxis);
                        localStorage.setItem(
                          "phonesMarginXAxis",
                          data.phonesMarginXAxis
                        );
                        setPhonesMarginBottom(data.phonesMarginBottom);
                        localStorage.setItem(
                          "phonesMarginBottom",
                          data.phonesMarginBottom
                        );

                        setInfoFontSize(data.infoFontSize);
                        localStorage.setItem("infoFontSize", data.infoFontSize);
                        setInfoMarginTop(data.infoMarginTop);
                        localStorage.setItem(
                          "infoMarginTop",
                          data.infoMarginTop
                        );
                        setInfoMarginXAxis(data.infoMarginXAxis);
                        localStorage.setItem(
                          "infoMarginXAxis",
                          data.infoMarginXAxis
                        );
                        setInfoMarginBottom(data.infoMarginBottom);
                        localStorage.setItem(
                          "infoMarginBottom",
                          data.infoMarginBottom
                        );

                        setInvoiceDescriptionFontSize(
                          data.invoiceDescriptionFontSize
                        );
                        localStorage.setItem(
                          "invoiceDescriptionFontSize",
                          data.invoiceDescriptionFontSize
                        );
                        setInvoiceDescriptionMarginTop(
                          data.invoiceDescriptionMarginTop
                        );
                        localStorage.setItem(
                          "invoiceDescriptionMarginTop",
                          data.invoiceDescriptionMarginTop
                        );
                        setInvoiceDescriptionMarginXAxis(
                          data.invoiceDescriptionMarginXAxis
                        );
                        localStorage.setItem(
                          "invoiceDescriptionMarginXAxis",
                          data.invoiceDescriptionMarginXAxis
                        );
                        setInvoiceDescriptionMarginBottom(
                          data.invoiceDescriptionMarginBottom
                        );
                        localStorage.setItem(
                          "invoiceDescriptionMarginBottom",
                          data.invoiceDescriptionMarginBottom
                        );
                        Swal.fire({
                          position: "top-end",
                          icon: "success",
                          title: translate(
                            "resources.root.downloadedSuccessfully"
                          ),
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      })
                      .catch((error) => {
                        if (error.response.status === 401) {
                          redirect("/Login");
                        }
                        Swal.fire({
                          position: "top-end",
                          icon: "error",
                          title: translate("resources.root.someErrorOccurred"),
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      });
                  }}
                  size={"lg"}
                >
                  <FaDownload /> {translate("resources.root.download")}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Grid>
      </Grid>
    </StylesProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    color: "rgba(0, 0, 0, 1) !important",
    opacity: 1,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  pos: {
    marginBottom: 12,
  },
  gridContainer: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: theme.direction === "rtl" ? "right" : "left",
  },
  centerPaper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  header: {
    width: "100%",
    backgroundColor: "red",
    color: theme.palette.common.white,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 7,
    borderRadius: 4,
    marginBottom: 7,
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderStyle: "solid",
    borderColor: "gray",
    borderRadius: 4,
    borderWidth: 1,
    padding: 10,
  },
  flexCol: {
    flex: 1,
    textAlign: theme.direction === "rtl" && "right",
  },
  table: {
    width: "100%",
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: "18px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default InvoiceMockup;
