import * as React from "react";
import { SelectInput, useNotify, useRedirect, useTranslate } from "react-admin";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { url, handleRequestResponse } from "../../request";
import MaterialTextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import swal from "sweetalert";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { Col, Form, Row } from "react-bootstrap";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "./../../assets/app.css";
import { HTTP } from "../../axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Select from "react-select";
import { useParams } from "react-router-dom";

export const StoreInventoryInvoiceUpdate = ({ props }) => {
  const { id } = useParams();
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [invoiceNumber, setInvoiceNumber] = React.useState(null);
  const [orderQtt, setOrderQtt] = React.useState(0);
  const [orderSmallQtt, setOrderSmallQtt] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [orders, setOrders] = React.useState([]);
  const [productNote, setProductNote] = React.useState("");
  const [orderToEdit, setOrderToEdit] = React.useState(-1);
  const [storeInventoryType, setStoreInventoryType] =
    React.useState("Periodic");
  const [delay, setDelay] = React.useState(false);
  const [extra, setExtra] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [jss, setJss] = React.useState(
    create({ plugins: [...jssPreset().plugins, rtl()] })
  );

  const redirect = useRedirect();

  React.useEffect(() => {
    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });
    HTTP.get(`${url}/StoreInventoryInvoice/${id}`).then((response) => {
      setInvoiceNumber(response.data.voucherNumber);
      setOrders(response.data.storeInventoryOrders);
      setSelectedDate(response.data.date);
      setProductNote(response.data.note);
    });
  }, []);

  React.useEffect(() => {
    setSelectedProduct(products.filter((el) => el.id === orderToEdit)[0]);
  }, [orderToEdit]);

  return (
    <StylesProvider jss={jss}>
      <Card className={classes.root}>
        <CardContent>
          <div className={classes.invoice}>
            <div className={classes.invoiceCols}>
              <MaterialTextField
                label={translate("resources.root.invoiceNumber")}
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
                className={classes.invoiceNumberInput}
              />
              <Form.Group className={`m-4`}>
                <Datetime
                  initialValue={selectedDate}
                  defaultShow={true}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm:ss"
                  onChange={(e) => {
                    setSelectedDate(e.format("YYYY-MM-DD HH:mm:ss"));
                  }}
                />
              </Form.Group>
            </div>
          </div>
          <div className={classes.orders}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <div className={classes.orderCols}>
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.productMeasure")} - ${
                              selectedProduct.productMeasure
                            }`
                          : translate("resources.root.productMeasure")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.oneMeasure")} - ${
                              selectedProduct.oneMeasure
                            }`
                          : translate("resources.root.oneMeasure")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.oneContains")} ${
                              selectedProduct.oneContains
                            } ${translate("resources.root.items")}`
                          : translate("resources.root.oneContains")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    {selectedProduct && (
                      <Chip
                        label={`${translate("resources.root.qty")}: ${
                          selectedProduct.qteInStock
                        } + ${selectedProduct.smallMeasureQte}`}
                        className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                      />
                    )}
                    {selectedProduct && (
                      <Chip
                        label={`${translate("resources.root.futureQty")}: 
                                                ${
                                                  extra
                                                    ? parseFloat(
                                                        selectedProduct.qteInStock
                                                      ) - parseFloat(orderQtt)
                                                    : parseFloat(
                                                        selectedProduct.qteInStock
                                                      ) + parseFloat(orderQtt)
                                                } + 
                                                ${
                                                  extra
                                                    ? parseFloat(
                                                        selectedProduct.smallMeasureQte
                                                      ) -
                                                      parseFloat(orderSmallQtt)
                                                    : parseFloat(
                                                        selectedProduct.smallMeasureQte
                                                      ) +
                                                      parseFloat(orderSmallQtt)
                                                }`}
                        className={`${classes.bottomLeftMargin} ${classes.successChips}`}
                      />
                    )}
                  </div>
                  <div className={classes.orderCols}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={products}
                      getOptionLabel={(option) =>
                        `${option.name} - ${option.barcode}`
                      }
                      style={{ width: 300 }}
                      onChange={(el, val) => {
                        setSelectedProduct(val);
                        setOrderQtt(1);
                      }}
                      renderInput={(params) => (
                        <MaterialTextField
                          {...params}
                          label={translate("resources.root.chooseProduct")}
                          variant="outlined"
                        />
                      )}
                    />
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.quantity")}
                      disabled={!selectedProduct}
                      value={orderQtt}
                      onFocus={(event) => event.target.select()}
                      onChange={(e) => {
                        setOrderQtt(e.target.value);
                      }}
                      className={classes.orderNumberInput}
                    />
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.smallMeasure")}
                      disabled={!selectedProduct}
                      value={orderSmallQtt}
                      onFocus={(event) => event.target.select()}
                      onChange={(e) => {
                        setOrderSmallQtt(e.target.value);
                      }}
                      onBlur={(e) => {
                        let currentValue = e.target.value;
                        if (currentValue >= selectedProduct.oneContains) {
                          setOrderSmallQtt(
                            currentValue % selectedProduct.oneContains
                          );
                          setOrderQtt(
                            parseInt(orderQtt) +
                              parseInt(
                                currentValue / selectedProduct.oneContains
                              )
                          );
                        } else {
                          setOrderSmallQtt(e.target.value);
                        }
                      }}
                      className={classes.orderNumberInput}
                    />

                    <div className={classes.invoice}>
                      <div className={classes.toggles}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={extra}
                              onChange={(e) => {
                                setExtra(e.target.checked);
                                setDelay(false);
                              }}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label={translate("resources.root.extra")}
                        />
                      </div>
                    </div>
                    <Button
                      variant="contained"
                      className={classes.botton}
                      disabled={parseFloat(orderQtt) <= 0 || !selectedProduct}
                      onClick={() => {
                        parseFloat(orderQtt) > 0 > 0 &&
                          setOrders((prev) => {
                            HTTP.post(`${url}/StoreInventoryOrders`, {
                              storeInventoryInvoiceId: props.id,
                              productId: selectedProduct.id,
                              barcode: selectedProduct.barcode,
                              name: selectedProduct.name,
                              payByMain: false,
                              oneContains: selectedProduct.oneContains,
                              productMeasure: selectedProduct.productMeasure,
                              productOneMeasure:
                                selectedProduct.productOneMeasure,
                              mcPurchasePrice: selectedProduct.mcPurchasePrice,
                              mcSellPrice: selectedProduct.mcSellPrice,
                              mcSmallMeasureSellPrice:
                                selectedProduct.mcSmallMeasureSellPrice,
                              mcSmallMeasurePurchasePrice:
                                selectedProduct.mcSmallMeasurePurchasePrice,
                              scPurchasePrice: selectedProduct.scPurchasePrice,
                              scSellPrice: selectedProduct.scSellPrice,
                              scSmallMeasureSellPrice:
                                selectedProduct.scSmallMeasureSellPrice,
                              scSmallMeasurePurchasePrice:
                                selectedProduct.scSmallMeasurePurchasePrice,
                              isExtra: extra,
                              qte: parseFloat(orderQtt),
                              smallMeasureQte: parseFloat(orderSmallQtt),
                            })
                              .then((e) => {
                                HTTP.get(
                                  `${url}/StoreInventoryInvoice/${props.id}`
                                ).then((response) => {
                                  setOrders(response.data.storeInventoryOrders);
                                });
                              })
                              .catch((e) => {
                                notify(e.response.data.message, {
                                  type: "error",
                                  undoable: true,
                                });
                              });
                          });
                      }}
                    >
                      {translate("resources.root.addOrder")}
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.botton}
                      style={{ marginLeft: 10 }}
                      disabled={true}
                    >
                      {translate("resources.root.noOfOrders")}:{" "}
                      {orders && orders.length}
                    </Button>
                  </div>

                  <div className={classes.orderCols}>
                    <TableContainer
                      component={Paper}
                      className={classes.tableContainer}
                    >
                      <Table
                        stickyHeader
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {translate("resources.root.barcode")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.name")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.quantity")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.smallMeasureQty")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.actions")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orders &&
                            orders.map((order) => (
                              <TableRow
                                key={order.barcode}
                                style={{
                                  backgroundColor: order.isExtra
                                    ? "#f63b3b"
                                    : "#2ef52e",
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {order.product.barcode}
                                </TableCell>
                                <TableCell align="right">
                                  {order.product.name}
                                </TableCell>
                                <TableCell align="right">{order.qte}</TableCell>
                                <TableCell align="right">
                                  {order.smallMeasureQte}
                                </TableCell>
                                <TableCell align="right">
                                  <DeleteIcon
                                    onClick={() => {
                                      swal({
                                        title: translate(
                                          "resources.root.makeSure"
                                        ),
                                        text: translate(
                                          "resources.root.confirmPerminantDelete"
                                        ),
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          HTTP.delete(
                                            `${url}/StoreInventoryOrders/${order.id}`
                                          )
                                            .then((e) => {
                                              HTTP.get(
                                                `${url}/StoreInventoryInvoice/${props.id}`
                                              ).then((response) => {
                                                setOrders(
                                                  response.data
                                                    .storeInventoryOrders
                                                );
                                              });
                                            })
                                            .catch((e) => {
                                              notify(e.response.data.message, {
                                                type: "error",
                                                undoable: true,
                                              });
                                            });
                                        }
                                      });
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Chip
                      label={`${translate("resources.root.finalQuantity")}: ${
                        orders &&
                        orders
                          .map((el) => parseFloat(el.qte))
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()
                      } +
                                                ${
                                                  orders &&
                                                  orders
                                                    .map((el) =>
                                                      parseFloat(
                                                        el.smallMeasureQte
                                                      )
                                                    )
                                                    .reduce((a, b) => a + b, 0)
                                                    ?.toLocaleString()
                                                } 
                                                `}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                  </div>
                  <div className={"m-2"}>
                    <Row>
                      <Col>
                        <Select
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          classNamePrefix="select"
                          options={[
                            {
                              value: "periodic",
                              label: translate("resources.root.periodic"),
                            },
                            {
                              value: "continuous",
                              label: translate("resources.root.continuous"),
                            },
                            {
                              value: "sudden",
                              label: translate("resources.root.sudden"),
                            },
                          ]}
                          onChange={(e) => {
                            setStoreInventoryType(e.value);
                          }}
                          defaultValue={{
                            value: "periodic",
                            label: translate("resources.root.periodic"),
                          }}
                        />
                      </Col>
                      <Col>
                        <MaterialTextField
                          label={translate("resources.root.note")}
                          value={productNote}
                          onChange={(e) => setProductNote(e.target.value)}
                          className={classes.noteInput}
                        />
                      </Col>
                    </Row>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          <Button
            size="medium"
            color="secondary"
            disabled={delay}
            variant="contained"
            onClick={() => {
              swal({
                title: translate("resources.root.makeSure"),
                text: translate("resources.root.confirmCreateNewInvoice"),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                setDelay(true);
                if (willDelete) {
                  setIsLoading(true);
                  HTTP.put(`${url}/StoreInventoryInvoice/${props.id}`, {
                    voucherNumber: invoiceNumber,
                    date: selectedDate,
                    inventoryType: storeInventoryType,
                    payByMain: false,
                    totalQte: orders
                      .map((el) => el.qte)
                      .reduce((a, b) => a + b, 0),
                    totalGiftQte: orders
                      .map((el) => el.smallMeasureQty)
                      .reduce((a, b) => a + b, 0),
                    note: productNote,
                  })
                    .then(() => {
                      localStorage.removeItem("StoreInventoryInvoiceAddOrders");
                      redirect("/StoreInventoryInvoice");
                    })
                    .catch((err) => {
                      setIsLoading(false);
                      notify(err.response.data.message, {
                        type: "error",
                        undoable: true,
                      });
                    });
                }
              });
            }}
          >
            {translate("ra.action.save")}
          </Button>
          <Button
            onClick={() => {
              swal({
                title: translate("resources.root.makeSure"),
                text: translate("resources.root.confirmCreateNewInvoice"),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  setOrders([]);
                }
              });
            }}
          >
            {translate("resources.root.clearOrders")}
          </Button>
        </CardActions>
      </Card>
      {isLoading && <LoadingScreen />}
    </StylesProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 72,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  dateTimeInput: {
    width: "100% !important",
    margin: "0 10px -35px",
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
    margin: "0 10px",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "350px !important",
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  successChips: {
    backgroundColor: "#72ffa7",
    color: "#000",
  },
  printWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "140%",
      marginLeft: theme.direction === "rtl" ? 0 : -230,
      marginRight: theme.direction === "rtl" ? -230 : -230,
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "80%",
      marginLeft: 265,
      paddingTop: 60,
      //   marginRight: 230,
    },
  },
}));
